import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import api from '../services/api';
import { encrypt } from '../services/crypt';

interface ILogContextData {
  ip_publico?: string;
  setLogAcesso(): void;
}

interface IDetails {
  country_code?: string;
  country_name?: string;
  city?: string;
  postal?: string;
  latitude?: number;
  longitude?: number;
  IPv4?: string;
  state?: string;
}

const LogContext = createContext<ILogContextData>({} as ILogContextData);

const LogProvider: React.FC = ({ children }) => {
  const [details, setDetails] = useState<IDetails>();

  useEffect(() => {
    fetch(
      'https://geolocation-db.com/json/f9902210-97f0-11eb-a459-b997d30983f1',
    )
      .then(response => response.json())
      .then(data => {
        setDetails(data);
        if (data) {
          api.defaults.headers.origin_ip = encrypt(data.IPv4);
          if (api.defaults.headers.authorization) {
            api.post('logs-acessos').catch(() => {
              console.log('Sistema não logado');
            });
          }
        }
      })
      .catch(() => {
        setDetails(undefined);
      });
  }, []);

  const setLogAcesso = useCallback(() => {
    if (details) {
      api.post('logs-acessos');
    }
  }, [details]);

  return (
    <LogContext.Provider
      value={{
        ip_publico: details && details.IPv4 ? details.IPv4 : undefined,
        setLogAcesso,
      }}
    >
      {children}
    </LogContext.Provider>
  );
};

function useLog(): ILogContextData {
  const context = useContext(LogContext);

  return context;
}

export { LogProvider, useLog };
