/* eslint-disable react/jsx-curly-newline */
import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { FiLock, FiMessageSquare, FiBell, FiBellOff } from 'react-icons/fi';
import logoImg from '../../assets/lagoinha_preto.png';
import RedesSociais from '../../assets/widget/social-media-cloud-svgrepo-com.svg';
import GoBack from '../../assets/widget/go-back-arrow-svgrepo-com.svg';
import Facebook from '../../assets/widget/facebook_preto.png';
import Youtube from '../../assets/widget/youtube_preto.png';
import Instagram from '../../assets/widget/instagram_preto.png';
import Whatsapp from '../../assets/widget/whatsapp_preto.png';
import ChristianChurchWidget from '../../assets/widget/christian-church-svgrepo-com.svg';
import Base4 from '../../assets/logo_base4.png';
import Forteens from '../../assets/logo_forteens.jpg';
// import Mulheres from '../../assets/logo_mulheres.jpg';
import Juniores from '../../assets/logo_juniores.jpg';
import TrintaMinutos from '../../assets/banner_30minutos.jpg';
import DonationWidget from '../../assets/widget/donation-svgrepo-com.svg';
import PhotoGallery from '../../assets/widget/photo-camera-svgrepo-com.svg';
// import Website from '../../assets/widget/internet-svgrepo-com.svg';
// import NoAR from '../../assets/widget/noar.gif';
import TitheWidget from '../../assets/widget/tithing-church-svgrepo-com.svg';
import Geolocation from '../../assets/widget/map-location-svgrepo-com.svg';
import GeolocationWidget from '../../assets/widget/geolocation-svgrepo-com.svg';
import MapFlag from '../../assets/widget/maps-and-flags-map-location-svgrepo-com.svg';
import QRCodeWidget from '../../assets/widget/qrcode-svgrepo-com(1).svg';
// import LogoOak from '../../assets/logotipo_oak.png';
import QRCodeLagoinha from '../../assets/qrcode_lagoinha.png';
import QRCodeAfrica from '../../assets/qrcode_africa.png';
// import REWidget from '../../assets/widget/home-delivery-service-svgrepo-com.svg';
import Heart from '../../assets/widget/heart-svgrepo-com.svg';
// import { useGeolocation } from '../../hooks/geolocation';
import { useNotification } from '../../hooks/notification';
import { usePermission } from '../../hooks/permission';

import './styles.css';

import {
  useStyles,
  Container,
  Content,
  AnimationContainer,
  Background,
  NotificationIcon,
  NotificationIconSetGranted,
  NotificationIconSetDenied,
  AreaRestrita,
  GridItem,
  WidgetButton,
  GridGroupItem,
  GridMapItem,
  GridInfoItem,
} from './styles';

const MainPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  // const { position } = useGeolocation();
  const [show] = useState(false);
  // const [show, setShow] = useState(false);
  const { browserSupport, getPermission, requestPermission } =
    useNotification();
  const { isAdmin } = usePermission();
  const [openMain, setOpenMain] = useState(true);
  const [openRedesSociais, setOpenRedesSociais] = useState(false);
  const [openProgramacao, setOpenProgramacao] = useState(false);
  const [openDizimoOferta, setOpenDizimoOferta] = useState(false);
  const [openDoacao, setOpenDoacao] = useState(false);
  const [openLocalizacao, setOpenLocalizacao] = useState(false);

  // useEffect(() => {
  //   if (
  //     position &&
  //     position.distanceIEQ &&
  //     position.distanceIEQ <= 50 &&
  //     show !== true
  //   ) {
  //     setShow(true);
  //   } else if (
  //     !position ||
  //     (position.distanceIEQ && position.distanceIEQ > 50 && show === true)
  //   ) {
  //     setShow(false);
  //   }
  // }, [position, show]);

  const Main: React.FC = () => {
    return (
      <div className={classes.root}>
        <Grid container>
          <GridItem item xs={6} sm={3} md={2}>
            <WidgetButton>
              <button
                type="button"
                onClick={() => {
                  setOpenMain(false);
                  setOpenProgramacao(true);
                }}
              >
                <div>
                  <img src={ChristianChurchWidget} alt="Programação" />
                </div>
              </button>
              <span>Programação</span>
            </WidgetButton>
          </GridItem>

          <GridItem item xs={6} sm={3} md={2}>
            <WidgetButton>
              <button
                type="button"
                onClick={() => {
                  setOpenMain(false);
                  setOpenRedesSociais(true);
                }}
              >
                <div>
                  <img src={RedesSociais} alt="Redes Sociais" />
                </div>
              </button>
              <span>Redes Sociais</span>
            </WidgetButton>
          </GridItem>
          <GridItem item xs={6} sm={3} md={2}>
            <WidgetButton>
              <button
                type="button"
                onClick={() => {
                  history.push('/localizador');
                }}
              >
                <div>
                  <img src={GeolocationWidget} alt="Localizar Célula" />
                </div>
              </button>
              <span>Localizar Célula</span>
            </WidgetButton>
          </GridItem>
          {(show || isAdmin) && (
            <GridItem item xs={6} sm={3} md={2}>
              <WidgetButton>
                <button
                  type="button"
                  onClick={() => {
                    history.push('/triagem');
                  }}
                >
                  <div>
                    <img src={GeolocationWidget} alt="Localizar Célula" />
                  </div>
                </button>
                <span>Triagem Célula</span>
              </WidgetButton>
            </GridItem>
          )}
          {/* <GridItem item xs={6} sm={3} md={2}>
            <WidgetButton>
              <button
                type="button"
                onClick={() =>
                  window.open('https://www.ieqlagoinha.com.br', '_blank')
                }
              >
                <div>
                  <img src={Website} alt="Nosso site" />
                </div>
              </button>
              <span>Nosso site</span>
            </WidgetButton>
          </GridItem> */}
          <GridItem item xs={6} sm={3} md={2}>
            <WidgetButton>
              <button
                type="button"
                onClick={() =>
                  window.open(
                    'https://www.facebook.com/ieqlagoinharp/photos/?ref=page_internal',
                    '_blank',
                  )
                }
              >
                <div>
                  <img src={PhotoGallery} alt="Galeria Fotos" />
                </div>
              </button>
              <span>Galeria Fotos</span>
            </WidgetButton>
          </GridItem>
          <GridItem item xs={6} sm={3} md={2}>
            <WidgetButton>
              <button
                type="button"
                onClick={() => {
                  setOpenMain(false);
                  setOpenDizimoOferta(true);
                }}
              >
                <div>
                  <img src={TitheWidget} alt="Dízimo e Oferta" />
                </div>
              </button>
              <span>Dízimo/Oferta</span>
            </WidgetButton>
          </GridItem>
          <GridItem item xs={6} sm={3} md={2}>
            <WidgetButton>
              <button
                type="button"
                onClick={() => {
                  setOpenMain(false);
                  setOpenDoacao(true);
                }}
              >
                <div>
                  <img src={DonationWidget} alt="Doações" />
                </div>
              </button>
              <span>Doações</span>
            </WidgetButton>
          </GridItem>
          <GridItem item xs={6} sm={3} md={2}>
            <WidgetButton>
              <button
                type="button"
                onClick={() => {
                  setOpenMain(false);
                  setOpenLocalizacao(true);
                }}
              >
                <div>
                  <img src={Geolocation} alt="Onde estamos" />
                </div>
              </button>
              <span>Localização</span>
            </WidgetButton>
          </GridItem>
          {/* <GridItem item xs={6} sm={3} md={2}>
            <WidgetButton>
              <button
                type="button"
                onClick={() => {
                  history.push('/prestador-servico');
                }}
              >
                <div>
                  <img src={REWidget} alt="Prestadores de Serviços" />
                </div>
              </button>
              <span>Prestadores de Serviços</span>
            </WidgetButton>
          </GridItem> */}
          {(show || isAdmin) && (
            <GridItem item xs={6} sm={3} md={2}>
              <WidgetButton>
                <button
                  type="button"
                  onClick={() => {
                    history.push('/batizantes-pre');
                  }}
                >
                  <div>
                    <img src={Heart} alt="Pré Cadastro" />
                  </div>
                </button>
                <span>Batismo Pré Cadastro</span>
              </WidgetButton>
            </GridItem>
          )}
          {(show || isAdmin) && (
            <GridItem item xs={6} sm={3} md={2}>
              <WidgetButton>
                <button
                  type="button"
                  onClick={() => {
                    history.push('/batizantes-sala-pre');
                  }}
                >
                  <div>
                    <img src={Heart} alt="Batismo Pré Cadastro" />
                  </div>
                </button>
                <span>Batismo PC</span>
              </WidgetButton>
            </GridItem>
          )}
          {(show || isAdmin) && (
            <GridItem item xs={6} sm={3} md={2}>
              <WidgetButton>
                <button
                  type="button"
                  onClick={() => {
                    history.push('/batizantes-sala');
                  }}
                >
                  <div>
                    <img src={Heart} alt="Batismo" />
                  </div>
                </button>
                <span>Batismo</span>
              </WidgetButton>
            </GridItem>
          )}
          {(show || isAdmin) && (
            <GridItem item xs={6} sm={3} md={2}>
              <WidgetButton>
                <button
                  type="button"
                  onClick={() => {
                    history.push('/batizantes-complemento');
                  }}
                >
                  <div>
                    <img src={Heart} alt="Batismo" />
                  </div>
                </button>
                <span>Batismo Complemento</span>
              </WidgetButton>
            </GridItem>
          )}
          {/* <GridItem item xs={6} sm={3} md={2}>
            <WidgetButton>
              <button
                type="button"
                onClick={() => {
                  setOpenMain(false);
                  setOpenDizimoOferta(true);
                }}
              >
                <div>
                  <img src={NoAR} alt="Ao Vivo" />
                </div>
              </button>
              <span>Ao Vivo</span>
            </WidgetButton>
          </GridItem> */}
          <GridItem item xs={6} sm={3} md={2}>
            <WidgetButton>
              <button
                type="button"
                onClick={() => history.push('/obter-qrcode')}
              >
                <div>
                  <img src={QRCodeWidget} alt="Obter QR Code" />
                </div>
              </button>
              <span>Obter QR Code</span>
            </WidgetButton>
          </GridItem>
        </Grid>
      </div>
    );
  };

  const RedesSociaisPage: React.FC = () => {
    return (
      <div className={classes.root}>
        <Grid container>
          <GridItem item xs={6} sm={3} md={2}>
            <WidgetButton>
              <button
                type="button"
                onClick={() => {
                  setOpenRedesSociais(false);
                  setOpenMain(true);
                }}
              >
                <div>
                  <img src={GoBack} alt="Voltar" />
                </div>
              </button>
              <span>Voltar</span>
            </WidgetButton>
          </GridItem>
          <GridItem item xs={6} sm={3} md={2}>
            <WidgetButton>
              <button
                type="button"
                onClick={() =>
                  window.open(
                    'https://www.facebook.com/andrecarvalhopastor',
                    '_blank',
                  )
                }
              >
                <div>
                  <img src={Facebook} alt="Facebook" />
                </div>
              </button>
              <span>Facebook</span>
            </WidgetButton>
          </GridItem>
          <GridItem item xs={6} sm={3} md={2}>
            <WidgetButton>
              <button
                type="button"
                onClick={() =>
                  window.open(
                    'https://www.youtube.com/channel/UCluobIToBdRwwbicKZNkuiA',
                    '_blank',
                  )
                }
              >
                <div>
                  <img src={Youtube} alt="Youtube" />
                </div>
              </button>
              <span>Youtube</span>
            </WidgetButton>
          </GridItem>
          <GridItem item xs={6} sm={3} md={2}>
            <WidgetButton>
              <button
                type="button"
                onClick={() =>
                  window.open(
                    'https://www.instagram.com/andrecarvalhopastor/',
                    '_blank',
                  )
                }
              >
                <div>
                  <img src={Instagram} alt="Instagram" />
                </div>
              </button>
              <span>Instagram</span>
            </WidgetButton>
          </GridItem>
          <GridItem item xs={6} sm={3} md={2}>
            <WidgetButton>
              <button
                type="button"
                onClick={() =>
                  window.open('https://wa.me/5516991269488', '_blank')
                }
              >
                <div>
                  <img src={Whatsapp} alt="Whatsapp" />
                </div>
              </button>
              <span>Whatsapp</span>
            </WidgetButton>
          </GridItem>
        </Grid>
      </div>
    );
  };

  const ProgramacaoPage: React.FC = () => {
    return (
      <div className={classes.root}>
        <Grid container>
          <GridItem item xs={12} sm={6} md={4}>
            <WidgetButton>
              <button
                type="button"
                onClick={() => {
                  setOpenProgramacao(false);
                  setOpenMain(true);
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <img src={GoBack} alt="Voltar" />
                  <span>Voltar</span>
                </div>
              </button>
            </WidgetButton>
          </GridItem>

          <GridGroupItem item xs={12} sm={6} md={4}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <img src={logoImg} height="100px" width="100px" alt="Culto" />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  marginLeft: '15px',
                  textAlign: 'left',
                }}
              >
                <p>Cuto de domingo</p>
                <p>Culto da família</p>
                <span>Domingo</span>
                <span>08:00hs</span>
                <span>10:30hs</span>
                <span>18:00hs</span>
              </div>
            </div>
          </GridGroupItem>
          <GridGroupItem item xs={12} sm={6} md={4}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              }}
            >
              <img
                id="cropped"
                src={TrintaMinutos}
                width="100px"
                height="100px"
                alt="30 Minutos com Deus"
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  marginLeft: '15px',
                  textAlign: 'left',
                }}
              >
                <p>30 Minutos com Deus</p>
                <p>Culto do Meio-dia</p>
                <span>Segunda, Quarta e Sexta</span>
                <span>11:30hs (Louvor)</span>
                <span>12:00hs (Palavra)</span>
              </div>
            </div>
          </GridGroupItem>
          <GridGroupItem item xs={12} sm={6} md={4}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              }}
            >
              <img src={logoImg} height="100px" width="100px" alt="Culto" />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  marginLeft: '15px',
                  textAlign: 'left',
                }}
              >
                <p>Escola de Sabedoria</p>
                <p>Venha conhecer mais da Palavra de Deus</p>
                <span>Quarta-feira</span>
                <span>18:45hs</span>
              </div>
            </div>
          </GridGroupItem>
          <GridGroupItem item xs={12} sm={6} md={4}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              }}
            >
              <img src={logoImg} height="100px" width="100px" alt="Culto" />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  marginLeft: '15px',
                  textAlign: 'left',
                }}
              >
                <p>Culto de quarta</p>
                {/* <p>Culto de quarta...</p> */}
                <span>Quarta-feira</span>
                <span>20:00hs</span>
              </div>
            </div>
          </GridGroupItem>
          <GridGroupItem item xs={12} sm={6} md={4}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              }}
            >
              <img src={Juniores} height="100px" width="100px" alt="Juniores" />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  marginLeft: '15px',
                  textAlign: 'left',
                }}
              >
                <p>Juniores Conectados</p>
                <p>Culto dos Juniores</p>
                <span>Sábado</span>
                <span>17:00hs</span>
              </div>
            </div>
          </GridGroupItem>
          <GridGroupItem item xs={12} sm={6} md={4}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              }}
            >
              <img src={Forteens} alt="Forteens" />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  marginLeft: '15px',
                  textAlign: 'left',
                }}
              >
                <p>Forteens</p>
                <p>Culto de Adolescentes</p>
                <span>Sábado</span>
                <span>17:00hs</span>
              </div>
            </div>
          </GridGroupItem>
          <GridGroupItem item xs={12} sm={6} md={4}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              }}
            >
              <img src={Base4} alt="Base 4" />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  marginLeft: '15px',
                  textAlign: 'left',
                }}
              >
                <p>Base 4</p>
                <p>Culto de Jovens</p>
                <span>Sábado</span>
                <span>20:00hs</span>
              </div>
            </div>
          </GridGroupItem>
        </Grid>
      </div>
    );
  };

  const DizimoOfertaPage: React.FC = () => {
    return (
      <div className={classes.root}>
        <Grid container>
          <GridItem item xs={12} sm={6} md={4}>
            <WidgetButton>
              <button
                type="button"
                onClick={() => {
                  setOpenDizimoOferta(false);
                  setOpenMain(true);
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <img src={GoBack} alt="Voltar" />
                  <span>Voltar</span>
                </div>
              </button>
            </WidgetButton>
          </GridItem>
          <GridInfoItem item xs={12} sm={6} md={4}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <p>Dízimo / Oferta</p>
              <span>Chave PIX: 62.955.505/4552-45</span>
              <span>Nome: Igreja Evangelho Quadrangular</span>
              <span>Cidade: Ribeirão Preto</span>
              <span>favor enviar comprovante de dízimo: (16) 99126-9488</span>
              <img
                src={QRCodeLagoinha}
                height="200px"
                width="200px"
                alt="QRCode Pix"
              />
            </div>
          </GridInfoItem>
        </Grid>
      </div>
    );
  };

  const DoacaoPage: React.FC = () => {
    return (
      <div className={classes.root}>
        <Grid container>
          <GridItem item xs={12} sm={6} md={4}>
            <WidgetButton>
              <button
                type="button"
                onClick={() => {
                  setOpenDoacao(false);
                  setOpenMain(true);
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <img src={GoBack} alt="Voltar" />
                  <span>Voltar</span>
                </div>
              </button>
            </WidgetButton>
          </GridItem>
          <GridInfoItem item xs={12} sm={6} md={4}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <p>Projeto África</p>
              <span>Chave PIX: 42.123.922/0001-36</span>
              <span>Nome: Instituto Oak – Projeto África</span>
              <span>Cidade: Ribeirão Preto</span>
              <span>favor enviar comprovante: (16) 99224-3611</span>
              <img
                src={QRCodeAfrica}
                height="200px"
                width="200px"
                alt="QRCode Pix"
              />
            </div>
          </GridInfoItem>
        </Grid>
      </div>
    );
  };

  const LocalizacaoPage: React.FC = () => {
    return (
      <div className={classes.root}>
        <Grid container>
          <GridItem item xs={6}>
            <WidgetButton>
              <button
                type="button"
                onClick={() => {
                  setOpenLocalizacao(false);
                  setOpenMain(true);
                }}
              >
                <div>
                  <img src={GoBack} alt="Voltar" />
                </div>
              </button>
              <span>Voltar</span>
            </WidgetButton>
          </GridItem>
          <GridItem item xs={6}>
            <WidgetButton>
              <button
                type="button"
                onClick={() =>
                  window.open('https://goo.gl/maps/aSqEqyiwtGWUHSheA', '_blank')
                }
              >
                <div>
                  <img src={MapFlag} alt="Como chegar" />
                </div>
              </button>
              <span>Como chegar?</span>
            </WidgetButton>
          </GridItem>
        </Grid>
        <Grid container>
          <GridMapItem item xs={12}>
            <div>
              <iframe
                title="IEQ Lagoinha"
                width="100%"
                height="100%"
                frameBorder={0}
                scrolling="no"
                marginHeight={0}
                marginWidth={0}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.973327905257!2d-47.777178585064604!3d-21.193218485909572!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94b9bfac482d6d0f%3A0x5c1c713045b1a604!2sIEQ%20Lagoinha%20Ribeir%C3%A3o%20Preto!5e0!3m2!1spt-BR!2sbr!4v1637759728004!5m2!1spt-BR!2sbr"
                style={{ border: 0 }}
                loading="lazy"
              />
            </div>
          </GridMapItem>
        </Grid>
      </div>
    );
  };

  return (
    <>
      {browserSupport && getPermission() === 'default' && (
        <NotificationIcon>
          <FiMessageSquare
            onClick={() => {
              requestPermission();
            }}
          />
        </NotificationIcon>
      )}
      {browserSupport && getPermission() === 'granted' && (
        <NotificationIconSetGranted>
          <FiBell />
        </NotificationIconSetGranted>
      )}
      {browserSupport && getPermission() === 'denied' && (
        <NotificationIconSetDenied>
          <FiBellOff />
        </NotificationIconSetDenied>
      )}
      <AreaRestrita>
        <FiLock
          onClick={() => {
            history.push('/home');
          }}
        />
      </AreaRestrita>
      <Background />
      <Container>
        <Content>
          <AnimationContainer>
            <img src={logoImg} height="200px" alt="IEQ Lagoinha" />
            {openMain && <Main />}
            {openRedesSociais && <RedesSociaisPage />}
            {openProgramacao && <ProgramacaoPage />}
            {openDizimoOferta && <DizimoOfertaPage />}
            {openDoacao && <DoacaoPage />}
            {openLocalizacao && <LocalizacaoPage />}
          </AnimationContainer>
        </Content>
      </Container>
    </>
  );
};

export default MainPage;
