import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
// import Brightness7 from '@material-ui/icons/Brightness7';
import MenuIcon from '@material-ui/icons/Menu';
// import NightsStay from '@material-ui/icons/NightsStay';
import { FiWifiOff, FiWifi } from 'react-icons/fi';
import React from 'react';
import { Link } from 'react-router-dom';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
// import { useTheme } from '../../hooks/theme';
import { useAuth } from '../../hooks/auth';
import { useStyles } from './styles';
import logoImg from '../../assets/lagoinha_preto.png';
import { useNetwork } from '../../hooks/network';

interface ToggleProps {
  handleDrawerToggle: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export function Header({ handleDrawerToggle }: ToggleProps): any {
  const classes = useStyles();
  const { user, signOut } = useAuth();
  const { isOnline } = useNetwork();
  // const { theme: currentTheme, toogleTheme } = useTheme();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  return (
    <AppBar className={classes.appBar}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          className={classes.navIconHide}
        >
          <MenuIcon />
        </IconButton>
        <img
          src={logoImg}
          alt="IEQ Lagoinha"
          height="40px"
          className={classes.imageHeader}
        />
        <div className={classes.userHeader}>
          <Typography variant="subtitle2" color="inherit" noWrap={isMobile}>
            <Link to="/perfil-discipulo" className={classes.linkUser}>
              {user.nome}
            </Link>
          </Typography>
          <Typography variant="subtitle2" color="inherit" noWrap={isMobile}>
            Seja bem vindo
          </Typography>
        </div>
        <Typography
          variant="h6"
          color="inherit"
          noWrap={isMobile}
          className={classes.title}
        >
          Sistema IEQ Lagoinha
        </Typography>
        {/* <IconButton
          color="inherit"
          aria-label="theme changer"
          className={classes.themeChanger}
          onClick={toogleTheme}
        >
          {currentTheme === 'light' ? <Brightness7 /> : <NightsStay />}
        </IconButton> */}
        <IconButton id="network_status" color="inherit" aria-label="online">
          {isOnline ? <FiWifi /> : <FiWifiOff />}
        </IconButton>
        {!isMobile && (
          <IconButton color="inherit" aria-label="logout" onClick={signOut}>
            <PowerSettingsNewIcon />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  );
}
