import React, { ButtonHTMLAttributes } from 'react';

import { Container } from './styles';

type ActionButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  backgroundColor?: string;
  fontColor?: string;
  height?: string;
  width?: string;
};

const ActionButton: React.FC<ActionButtonProps> = ({
  children,
  loading,
  backgroundColor,
  fontColor,
  height,
  width,
  ...rest
}) => (
  <Container
    type="button"
    backgroundColor={backgroundColor}
    fontColor={fontColor}
    height={height}
    width={width}
    {...rest}
  >
    {loading ? 'Carregando...' : children}
  </Container>
);

export default ActionButton;
