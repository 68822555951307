const formatarCelular = (value: string): string => {
  let celular = value;
  celular = celular.replace(/\D/g, ''); // Remove tudo o que não é dígito
  celular = celular.replace(/^(\d{2})(\d)/g, '($1) $2'); // Coloca parênteses em volta dos dois primeiros dígitos
  celular = celular.replace(/(\d)(\d{4})$/, '$1-$2'); // Coloca hífen entre o quarto e o quinto dígitos
  return celular;
};

const validarCelular = (celular: string): boolean => {
  const regex = new RegExp(
    '^((1[1-9])|([2-9][0-9]))((3[0-9]{3}[0-9]{4})|(9[0-9]{3}[0-9]{5}))$',
  );
  return regex.test(celular);
};

export { formatarCelular, validarCelular };
