import React, { useState, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  FiPhone,
  FiLock,
  FiCalendar,
  FiDownload,
  FiWifi,
  FiWifiOff,
} from 'react-icons/fi';
import html2canvas from 'html2canvas';
import { QRCode } from 'react-qrcode-logo';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import logoImg from '../../../assets/lagoinha_preto.png';
import qrcodeImg from '../../../assets/lagoinha_cinza.png';
import api from '../../../services/api';
import { decrypt, encryptUUID } from '../../../services/crypt';
import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';
import { useNetwork } from '../../../hooks/network';

import Button from '../../../components/Button';
import Input from '../../../components/Input';

import {
  Container,
  Content,
  AnimationContainer,
  Background,
  AreaRestrita,
  QrCodeDownload,
  NetworkStatus,
} from './styles';

declare type CornerRadii = number | number[] | InnerOuterRadii;
declare type InnerOuterRadii = {
  inner: number | number[];
  outer: number | number[];
};
export interface IProps {
  value?: string;
  ecLevel?: 'L' | 'M' | 'Q' | 'H';
  enableCORS?: boolean;
  size?: number;
  quietZone?: number;
  bgColor?: string;
  fgColor?: string;
  logoImage?: string;
  logoWidth?: number;
  logoHeight?: number;
  logoOpacity?: number;
  eyeRadius?: CornerRadii | CornerRadii[];
  qrStyle?: 'squares' | 'dots';
  style?: object;
}

interface IDiscipulo {
  id: string;
}

interface IGeradorQrCodePageFormData {
  celular: string;
  data_nascimento: string;
}

const GeradorQrCodePage: React.FC<IGeradorQrCodePageFormData> = () => {
  const { addToast } = useToast();
  const { isOnline } = useNetwork();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [idDiscipulo, setIdDiscipulo] = useState<string>('');
  const [state, setState] = useState<IProps>({
    logoImage: qrcodeImg,
    value: '',
    logoWidth: 57,
    ecLevel: 'H',
    enableCORS: true,
    eyeRadius: [
      // build eyeRadius manually
      {
        outer: [0, 0, 0, 0],
        inner: [0, 0, 0, 0],
      },
      {
        outer: [0, 0, 0, 0],
        inner: [0, 0, 0, 0],
      },
      {
        outer: [0, 0, 0, 0],
        inner: [0, 0, 0, 0],
      },
    ],
  });

  const handleDownload = (): void => {
    const elems = document.querySelector('#react-qrcode-logo') as any;
    if (elems) {
      elems.style.width = '170px';
      elems.style.height = '170px';
    }
    html2canvas(document.querySelector('#react-qrcode-logo') as any).then(
      function (canvas) {
        const link = document.createElement('a');
        link.download = 'react-qrcode-logo.png';
        link.href = canvas.toDataURL();
        link.click();
      },
    );
  };

  const handleBuscarQrCode = useCallback(
    async (data: IGeradorQrCodePageFormData) => {
      setIdDiscipulo('');

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          celular: Yup.string()
            .required()
            .matches(/^[0-9]+$/, 'Deve ser somente dígitos')
            .min(11, 'Deve ter exatamente 11 dígitos')
            .max(11, 'Deve ter exatamente 11 dígitos'),
          data_nascimento: Yup.string()
            .required()
            .matches(/^[0-9]+$/, 'Deve ser somente dígitos')
            .min(8, 'Deve ter exatamente 8 dígitos')
            .max(8, 'Deve ter exatamente 8 dígitos'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (data.celular && data.data_nascimento) {
          const celularBusca = data.celular
            .replace('(', '')
            .replace(')', '')
            .replace(' ', '')
            .replace('-', '');
          const dataNascimentoBusca = data.data_nascimento
            .replace('(', '')
            .replace(')', '')
            .replace(' ', '')
            .replace('-', '');
          api
            .get(`qrcode/${celularBusca}/${dataNascimentoBusca}`)
            .then(response => {
              const content = decrypt<IDiscipulo>(response.data);
              setState(prevState => ({
                ...prevState,
                value: encryptUUID(content.id),
              }));
              setIdDiscipulo(content.id);
              addToast({
                type: 'success',
                title: 'QR Code gerado!',
              });
            })
            .catch(() => {
              addToast({
                type: 'error',
                title: 'Erro ao gerar QR Code!',
                description: 'Cadastro não encontrado.',
              });
            });
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu um erro ao fazer login, cheque as credenciais.',
        });
      }
    },
    [addToast],
  );

  return (
    <>
      <NetworkStatus isOnline={isOnline}>
        {isOnline ? <FiWifi /> : <FiWifiOff />}
      </NetworkStatus>
      <AreaRestrita>
        <FiLock
          onClick={() => {
            history.push('/home');
          }}
        />
      </AreaRestrita>
      <Container>
        <Background />

        <Content>
          <AnimationContainer>
            <img src={logoImg} height="200px" alt="IEQ Lagoinha" />

            <Form ref={formRef} onSubmit={handleBuscarQrCode}>
              <h2>QR Code Discípulo</h2>

              <Input
                name="celular"
                icon={FiPhone}
                placeholder="Celular com DDD"
              />
              <Input
                name="data_nascimento"
                icon={FiCalendar}
                placeholder="DDMMAAAA"
              />

              <Button type="submit" disabled={!isOnline}>
                Gerar
              </Button>
            </Form>
            {idDiscipulo && (
              <QrCodeDownload>
                <QRCode
                  {...{
                    ...state,
                  }}
                />
                <div>
                  <FiDownload
                    onClick={() => {
                      handleDownload();
                    }}
                  />
                </div>
              </QrCodeDownload>
            )}
          </AnimationContainer>
        </Content>
      </Container>
    </>
  );
};

export default GeradorQrCodePage;
