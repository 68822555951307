import React, { createContext, useState, useContext, useEffect } from 'react';
import { distanceHaversine } from '../utils/locationDistance';

interface PositionUser {
  accuracy: number | null;
  altitude: number | null;
  altitudeAccuracy: number | null;
  heading: number | null;
  latitude: number | null;
  longitude: number | null;
  speed: number | null;
  distanceIEQ: number | null;
}

interface IGeolocationContextData {
  position?: PositionUser;
  getPosition: () => PositionUser | undefined;
}

const GeolocationContext = createContext<IGeolocationContextData>(
  {} as IGeolocationContextData,
);

const GeolocationProvider: React.FC = ({ children }) => {
  const [positionUser, setPositionUser] =
    useState<PositionUser | undefined>(undefined);

  const getCurrentPosition = (): PositionUser | undefined => {
    let currentPositionReturn: PositionUser | undefined;
    if ('geolocation' in navigator) {
      const watcher = navigator.geolocation.watchPosition(
        ({ coords }) => {
          const distanciaIEQ = distanceHaversine(
            coords.latitude,
            coords.longitude,
            -21.19310344896468,
            -47.77497917381346,
          );
          const currentPosition = {
            accuracy: coords.accuracy,
            altitude: coords.altitude,
            altitudeAccuracy: coords.altitudeAccuracy,
            heading: coords.heading,
            latitude: coords.latitude,
            longitude: coords.longitude,
            speed: coords.speed,
            distanceIEQ: distanciaIEQ,
          } as PositionUser;
          currentPositionReturn = currentPosition;
          setPositionUser(currentPosition);
          navigator.geolocation.clearWatch(watcher);
          return currentPositionReturn;
        },
        error => {
          console.log(error);
        },
        {
          enableHighAccuracy: true,
          timeout: 40 * 1000,
          maximumAge: 5 * 60 * 1000,
        },
      );
    }
    return undefined;
  };

  useEffect(() => {
    getCurrentPosition();
  }, []);

  const getPosition = (): PositionUser | undefined => {
    const currentPosition = getCurrentPosition();
    return currentPosition;
  };

  return (
    <GeolocationContext.Provider
      value={{
        position: positionUser,
        getPosition,
      }}
    >
      {children}
    </GeolocationContext.Provider>
  );
};

function useGeolocation(): IGeolocationContextData {
  const context = useContext(GeolocationContext);

  return context;
}

export { GeolocationProvider, useGeolocation };
