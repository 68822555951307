import React from 'react';
import ImageCrop from '../../../components/ImageCrop/index.js';

interface ICropImageProps {
  fileToCrop: any;
  onCropped: any;
}

const CropImage: React.FC<ICropImageProps> = ({ fileToCrop, onCropped }) => {
  return (
    <ImageCrop fileToCrop={fileToCrop} onCropped={onCropped} aspect={3 / 4} />
  );
};

export default CropImage;
