import styled from 'styled-components';
import { shade } from 'polished';

interface ActionButtonProps {
  backgroundColor?: string;
  fontColor?: string;
  height?: string;
  width?: string;
}

export const Container = styled.button<ActionButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.backgroundColor || '#000000'};
  min-width: 25px;
  min-height: 25px;
  @media screen and (max-width: 320px) {
    min-width: 20px;
    min-height: 20px;
  }
  width: ${props => props.width || '25px'};
  height: ${props => props.height || '25px'};
  border-radius: 50%;
  border: 0;
  color: ${props => props.fontColor || '#ffffff'};
  transition: background-color 0.2s;

  svg {
    font-size: ${props =>
      (Number(props.width?.replace('px', '')) || 25) / 1.5}px;

    @media screen and (max-width: 320px) {
      font-size: ${props =>
        (Number(props.width?.replace('px', '')) || 20) / 1.5}px;
    }
  }

  &:hover {
    background: ${props =>
      props.backgroundColor
        ? shade(0.2, props.backgroundColor)
        : shade(0.2, '#222222')};
  }
`;
