import { makeStyles } from '@material-ui/core/styles';
import styled, { keyframes } from 'styled-components';
import { Grid } from '@material-ui/core';

import mainPageBackgroundImg from '../../assets/imagem_fundo.jpg';

export const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'auto',
    height: '100%',
    textAlign: 'center',
    paddingTop: 15,
    paddingLeft: 15,
    paddingRight: 15,
  },
}));

export const GridItem = styled(Grid)`
  padding-top: 10px;
  padding-bottom: 20px;
  @media (min-width: 415px) {
    padding: 50px;
  }
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;

  button {
    border: 0;
    background-color: transparent;
  }
`;

export const GridGroupItem = styled(Grid)`
  margin: 10px;
  padding: 10px;
  @media (min-width: 415px) {
    padding: 50px;
  }
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  border: 1px solid #eee;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);

  button {
    border: 0;
    background-color: transparent;
  }

  > div {
    > img {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      border: 1px solid #000;
    }
  }

  #cropped {
    object-fit: cover;
    height: 100px;
    width: 100px;
    border-radius: 50%;
  }
`;

export const GridInfoItem = styled(Grid)`
  margin: 10px;
  padding: 10px;
  @media (min-width: 415px) {
    padding: 50px;
  }
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  border: 1px solid #eee;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);

  button {
    border: 0;
    background-color: transparent;
  }

  > div {
    p {
      font-family: 'Roboto Slab', serif;
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    span {
      font-family: 'Roboto Slab', serif;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    img {
      margin-top: 15px;
    }
  }
`;

export const GridMapItem = styled(Grid)`
  margin: 10px;

  > div {
    position: relative;
    padding-bottom: 75%; // This is the aspect ratio
    height: 0;
    overflow: hidden;

    > iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
    }
  }
`;

export const Container = styled.div`
  position: relative;
  height: 100%;

  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  position: absolute;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  height: 100%;
  z-index: 3;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;

  animation: ${appearFromLeft} 1s;

  > img {
    align-self: center;
    height: 200px;
    @media (max-width: 1024px) {
      height: 150px;
    }
    @media (max-width: 320px) {
      height: 125px;
    }
  }
`;

export const WidgetButton = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  @media (min-width: 415px) {
    padding-left: 50px;
    padding-right: 50px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  > button {
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 150px;
      height: 150px;
      @media (max-width: 1024px) {
        width: 100px;
        height: 100px;
      }
      @media (max-width: 320px) {
        width: 75px;
        height: 75px;
      }

      > img {
        align-self: center;
        width: 150px;
        @media (max-width: 1024px) {
          width: 100px;
        }
        @media (max-width: 320px) {
          width: 75px;
        }
      }

      #aovivo {
        height: 150px;
        @media (max-width: 1024px) {
          height: 100px;
        }
        @media (max-width: 320px) {
          height: 75px;
        }
        border-radius: 50%;
      }
    }
  }

  span {
    margin-top: 20px;
    font-family: 'Roboto Slab', serif;
    font-size: 16px;
    font-weight: bold;
  }
`;

export const Background = styled.div`
  flex: 1;
  position: absolute;
  background: url(${mainPageBackgroundImg}) no-repeat center;
  background-size: cover;
  opacity: 0.3;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

export const AreaRestrita = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 10;

  svg {
    height: 30px;
    width: 30px;
    z-index: 20;
    color: #000000;

    transition: filter 0.2s;

    &:hover {
      //filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
      filter: drop-shadow(0 0 15px red);
    }
  }
`;

export const NotificationIcon = styled.div`
  position: absolute;
  left: 15px;
  top: 15px;
  z-index: 10;

  svg {
    height: 30px;
    width: 30px;
    z-index: 20;
    color: #000000;

    transition: filter 0.2s;

    &:hover {
      //filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
      filter: drop-shadow(0 0 15px green);
    }
  }
`;

export const NotificationIconSetGranted = styled.div`
  position: absolute;
  left: 15px;
  top: 15px;
  z-index: 10;

  svg {
    height: 30px;
    width: 30px;
    z-index: 20;
    color: darkgreen;
  }
`;

export const NotificationIconSetDenied = styled.div`
  position: absolute;
  left: 15px;
  top: 15px;
  z-index: 10;

  svg {
    height: 30px;
    width: 30px;
    z-index: 20;
    color: red;
  }
`;
