import CryptoJS from 'crypto-js';
import cryptoConfig from '../config/crypt';

const encrypt = (payload: string): string => {
  return CryptoJS.AES.encrypt(
    payload,
    cryptoConfig.secret.split('').reverse().join(''),
  ).toString();
};

const encryptUUID = (payload: string): string => {
  return CryptoJS.TripleDES.encrypt(
    payload,
    cryptoConfig.secret.split('').reverse().join(''),
  ).toString();
};

const decryptUUID = (payload: string): string => {
  const bytes = CryptoJS.TripleDES.decrypt(
    payload,
    cryptoConfig.secret.split('').reverse().join(''),
  );
  return bytes.toString(CryptoJS.enc.Utf8);
};

const decrypt = <T>(payload: string): T => {
  const bytes = CryptoJS.AES.decrypt(
    payload,
    cryptoConfig.secret.split('').reverse().join(''),
  );
  return JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
};

const decryptString = (payload: string): string => {
  const bytes = CryptoJS.AES.decrypt(
    payload,
    cryptoConfig.secret.split('').reverse().join(''),
  );
  return bytes.toString(CryptoJS.enc.Utf8);
};

export { encrypt, decrypt, decryptString, encryptUUID, decryptUUID };
