import React from 'react';
import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Button,
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { useStyles } from './styles';

interface QuestionDialog {
  isOpen: boolean;
  title: string;
  subTitle: string;
  onConfirm?: () => void;
}

type QuestionProps = DialogProps & {
  questionDialog: QuestionDialog;
  setQuestionDialog: React.Dispatch<React.SetStateAction<QuestionDialog>>;
};

export const QuestionDialog: React.FC<QuestionProps> = ({
  questionDialog,
  setQuestionDialog,
}) => {
  const classes = useStyles();

  return (
    <Dialog open={questionDialog.isOpen} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>
        <IconButton disableRipple className={classes.titleIcon}>
          <HelpIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="h6">{questionDialog.title}</Typography>
        <Typography variant="subtitle2">{questionDialog.subTitle}</Typography>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button
          color="default"
          onClick={() =>
            setQuestionDialog({ ...questionDialog, isOpen: false })
          }
        >
          Não
        </Button>
        <Button color="default" onClick={questionDialog.onConfirm}>
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  );
};
