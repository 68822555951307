import React from 'react';
import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Button,
} from '@material-ui/core';
// import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useStyles } from './styles';

interface ConfirmDialog {
  isOpen: boolean;
  title: string;
  subTitle: string;
  onConfirm?: () => void;
}

type ConfirmProps = DialogProps & {
  confirmDialog: ConfirmDialog;
  setConfirmDialog: React.Dispatch<React.SetStateAction<ConfirmDialog>>;
};

export const ConfirmDialog: React.FC<ConfirmProps> = ({
  confirmDialog,
  setConfirmDialog,
}) => {
  const classes = useStyles();

  return (
    <Dialog open={confirmDialog.isOpen} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>
        <IconButton disableRipple className={classes.titleIcon}>
          <DeleteForeverIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="h6">{confirmDialog.title}</Typography>
        <Typography variant="subtitle2">{confirmDialog.subTitle}</Typography>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button
          color="default"
          onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
        >
          Não
        </Button>
        <Button color="secondary" onClick={confirmDialog.onConfirm}>
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  );
};
