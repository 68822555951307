import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from './styles';

interface Props {
  title: string;
  children: any;
  openPopup: boolean;
  setOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
  // eslint-disable-next-line react/require-default-props
  closeDisabled?: boolean;
}

export function Popup(props: Props) {
  const classes = useStyles();
  const { title, children, openPopup, setOpenPopup, closeDisabled } = props;

  return (
    <Dialog open={openPopup} className={classes.dialog}>
      <DialogTitle>
        <div style={{ display: 'flex' }}>
          <Typography
            variant="h6"
            component="div"
            style={{ flexGrow: 1, marginRight: '30px' }}
            className={classes.titleHead}
          >
            {title}
          </Typography>
          {!closeDisabled && (
            <CloseIcon
              color="secondary"
              onClick={() => {
                setOpenPopup(false);
              }}
            />
          )}
        </div>
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
}
