import { makeStyles, Theme } from '@material-ui/core/styles';
import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

import mainPageBackgroundImg from '../../../assets/imagem_fundo.jpg';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: 20,
    [theme.breakpoints.down('md')]: {
      paddingTop: 50,
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  lista: {
    display: 'flex',
    overflow: 'auto',
    height: '100%',
    alignItems: 'flex-start',
    padding: 20,
    paddingTop: 5,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  grid: {
    padding: 15,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },

  button: {
    marginBottom: 15,
  },

  titleHead: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  titleHeadH5: {
    marginTop: '-5px',
    fontSize: '14px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  cell: {
    textAlign: 'center',
  },
  searchInput: {
    width: '50%',
  },
  inputForm: {
    width: '100%',
    margin: 10,
  },
  buttonForm: {
    color: theme.palette.primary.contrastText,
    margin: 20,
  },
  desktop: {
    overflow: 'auto',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  mobile: {
    overflow: 'auto',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 350,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

export const FotoPass = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 768px) {
    align-items: flex-start;
  }

  > svg {
    width: 40px;
    height: 40px;
    color: #111111;

    @media screen and (max-width: 768px) {
      width: 35px;
      height: 35px;
    }

    transition: filter 0.2s;

    &:hover {
      //filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
      filter: drop-shadow(0 0 15px #111111);
    }
  }
`;

export const FotoInput = styled.div`
  position: relative;
  align-self: center;
  width: 160px;

  img {
    width: 140px;
    height: 186px;
    border-radius: 4px;
  }

  label {
    position: absolute;
    width: 48px;
    height: 48px;
    background: #999;
    border-radius: 50%;
    right: 0;
    bottom: 0;
    border: 0;
    cursor: pointer;
    transition: background-color 0.2s;
    z-index: 20;

    display: flex;
    align-items: center;
    justify-content: center;

    input {
      display: none;
      z-index: 20;
    }

    svg {
      width: 20px;
      height: 20px;
      color: #111111;
    }

    &:hover {
      background: ${shade(0.2, '#999')};
    }
  }
`;

export const Container = styled.div`
  > header {
    height: 144px;
    background: #28262e;

    display: flex;
    align-items: center;

    div {
      width: 100%;
      max-width: 1120px;
      margin: 0 auto;

      svg {
        color: #999591;
        width: 24px;
        height: 24px;
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: -176px auto 0;

  width: 100%;

  form {
    margin: 80px 0;
    width: 340px;
    text-align: center;
    display: flex;
    flex-direction: column;

    h1 {
      margin-bottom: 24px;
      font-size: 20px;
      text-align: left;
    }

    a {
      color: #f4ede8;
      display: block;
      margin-top: 24px;
      text-decoration: none;
      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#f4ede8')};
      }
    }

    input[name='old_password'] {
      margin-top: 24px;
    }
  }
`;

export const ItemLista = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 10px;

  > div {
    position: relative;
    //margin: 10px;
    padding: 5px;
    padding-bottom: 30px;
    border: 1px solid #555;
    border-radius: 10px;

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;

      > p {
        font-family: 'Roboto Slab', serif;
        font-size: 9px;
        font-weight: bold;
      }

      > span {
        margin-left: 5px;
        font-family: 'Roboto Slab', serif;
        font-size: 12px;
      }
    }
  }
`;

export const ItemBotoes = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  right: 5px;
  bottom: 5px;

  button + button {
    margin-left: 5px;
  }
`;

export const CEPField = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  > button {
    height: 35px;
  }
`;

export const ContainerErro = styled.div`
  position: relative;
  height: 100%;

  display: flex;
  align-items: stretch;
`;

export const ContentErro = styled.div`
  position: absolute;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  height: 100%;
  z-index: 3;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainerErro = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;

  animation: ${appearFromLeft} 1s;

  > img {
    margin-top: 20px;
    align-self: center;
    height: 300px;
    @media (max-width: 1024px) {
      height: 200px;
    }
    @media (max-width: 320px) {
      height: 160px;
    }
  }

  > h1 {
    margin-top: 20px;
    font-size: 64px;
    @media (max-width: 1024px) {
      font-size: 32px;
    }
    @media (max-width: 320px) {
      font-size: 24px;
    }
  }
`;

export const BackgroundErro = styled.div`
  flex: 1;
  position: absolute;
  background: url(${mainPageBackgroundImg}) no-repeat center;
  background-size: cover;
  opacity: 0.3;
  width: 100%;
  height: 100%;
  z-index: 2;
`;
