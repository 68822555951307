import React, { useEffect, useState, useCallback } from 'react';
import {
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  Input,
  Chip,
  MenuItem,
  Fade,
  CircularProgress,
} from '@material-ui/core';
import axios from 'axios';
import { FiCamera } from 'react-icons/fi';
import InputMask from 'react-input-mask';
import { parse } from 'date-fns';
import { useTheme, Theme } from '@material-ui/core/styles';
import { useForm, Form } from '../../../components/UseForm/useForm.js';
import { useStyles, CEPField, FotoInput, FotoPass } from './styles';
import Controls from '../../../components/controls/Controls.js';
import { decrypt, encrypt } from '../../../services/crypt';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import CropImage from './CropImage';
import b64toBlob from '../../../utils/b64ToBlob';
import resize from '../../../utils/resize.js';
import CEPForm from './CEPForm';
import { Popup } from '../../../components/index';
import semImagem from '../../../assets/img_indisponivel.png';
import {
  formatarCelular,
  validarCelular,
} from '../../../utils/formatarCelular';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

function getStyles(
  name: string,
  batizantesSelecionados: string[],
  theme: Theme,
): any {
  return {
    fontWeight:
      batizantesSelecionados.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

interface ICidade {
  id: string;
  nome: string;
  id_estado: string;
}

interface IEstadoSelect {
  id?: string;
  title?: string;
  sigla?: string;
}

interface IDiscipuloSelect {
  id?: string;
  title?: string;
}

interface IBatizante {
  id: string;
  nome: string;
  celular?: string;
  celularFormatted?: string;
}

interface IValue {
  id?: string;
  id_batismo?: string;
  nome?: string;
  sexo?: string;
  estado_civil?: string;
  data_nascimento?: Date;
  celular?: string;
  email?: string;
  data_batismo?: Date;
  cep?: string;
  endereco?: string;
  numero?: number;
  complemento?: string;
  bairro?: string;
  cidade?: string;
  estado?: string;
  ibge?: string;
  batismo_conjunto: boolean;
  fez_casa_paz: boolean;
  id_lider_casa_paz?: string;
  lider_casa_paz?: string;
  frequenta_celula: boolean;
  id_lider_celula?: string;
  lider_celula?: string;
  nome_celula?: string;
  observacao?: string;
  foto_url?: string;
  nomes_batismo_conjunto?: string;
  religiao_veio?: string;
  modo_casa_paz?: string;
  dia_horario?: string;
  domingo_manha?: boolean;
  domingo_tarde?: boolean;
  domingo_noite?: boolean;
  segunda_manha?: boolean;
  segunda_tarde?: boolean;
  segunda_noite?: boolean;
  terca_manha?: boolean;
  terca_tarde?: boolean;
  terca_noite?: boolean;
  quarta_manha?: boolean;
  quarta_tarde?: boolean;
  quarta_noite?: boolean;
  quinta_manha?: boolean;
  quinta_tarde?: boolean;
  quinta_noite?: boolean;
  sexta_manha?: boolean;
  sexta_tarde?: boolean;
  sexta_noite?: boolean;
  sabado_manha?: boolean;
  sabado_tarde?: boolean;
  sabado_noite?: boolean;
  batizantes_juntos?: string;
  nome_contato?: string;
  telefone_contato?: string;
  facebook?: string;
  instagram?: string;
}

interface IError {
  id_batismo?: string;
  nome?: string;
  sexo?: string;
  estado_civil?: string;
  data_nascimento?: string;
  celular?: string;
  data_batismo?: string;
  cep?: string;
  endereco?: string;
  numero?: string;
  bairro?: string;
  cidade?: string;
  estado?: string;
  modo_casa_paz?: string;
  religiao_veio?: string;
}

const initialFValues = {
  id: '',
  id_batismo: 'f0e6b1ed-053a-473a-8c1c-b02403dd7686',
  nome: '',
  sexo: '',
  estado_civil: '',
  data_nascimento: undefined,
  celular: '',
  email: '',
  data_batismo: undefined,
  cep: '',
  endereco: '',
  numero: 0,
  complemento: '',
  bairro: '',
  cidade: '',
  estado: '',
  ibge: '',
  batismo_conjunto: false,
  fez_casa_paz: false,
  id_lider_casa_paz: undefined,
  lider_casa_paz: '',
  frequenta_celula: false,
  id_lider_celula: undefined,
  lider_celula: '',
  nome_celula: '',
  observacao: '',
  foto_url: '',
  modo_casa_paz: '',
  dia_horario: '',
  domingo_manha: false,
  domingo_tarde: false,
  domingo_noite: false,
  segunda_manha: false,
  segunda_tarde: false,
  segunda_noite: false,
  terca_manha: false,
  terca_tarde: false,
  terca_noite: false,
  quarta_manha: false,
  quarta_tarde: false,
  quarta_noite: false,
  quinta_manha: false,
  quinta_tarde: false,
  quinta_noite: false,
  sexta_manha: false,
  sexta_tarde: false,
  sexta_noite: false,
  sabado_manha: false,
  sabado_tarde: false,
  sabado_noite: false,
  nomes_batismo_conjunto: '',
  religiao_veio: '',
  batizantes_juntos: undefined,
  nome_contato: '',
  telefone_contato: '',
  facebook: '',
  instagram: '',
} as IValue;

const initialFErrors = {
  id_batismo: '',
  nome: '',
  sexo: '',
  estado_civil: '',
  data_nascimento: '',
  celular: '',
  data_batismo: '',
  cep: '',
  endereco: '',
  numero: '',
  bairro: '',
  cidade: '',
  estado: '',
  modo_casa_paz: '',
  dia_horario: '',
  religiao_veio: '',
} as IError;

interface IBatizanteFormProps {
  addOrEdit: any;
  recordForEdit: IValue | null;
  updateFoto: any;
  discipulos: IDiscipuloSelect[];
  estados: IEstadoSelect[];
  cidades: ICidade[];
}

const BatizanteForm: React.FC<IBatizanteFormProps> = ({
  addOrEdit,
  recordForEdit,
  updateFoto,
  discipulos,
  estados,
  cidades,
}) => {
  const classes = useStyles();
  const { addToast } = useToast();
  const theme = useTheme();

  const [batizantes, setBatizantes] = useState<string[]>([]);
  const [idBatizantes, setIdBatizantes] = useState<string[]>([]);
  const [liderCasaPaz, setLiderCasaPaz] = useState<IDiscipuloSelect>(
    recordForEdit
      ? { id: recordForEdit.id_lider_casa_paz, title: '' }
      : { id: '', title: '' },
  );
  const [liderCasaPazAnterior, setLiderCasaPazAnterior] = useState(
    recordForEdit ? recordForEdit.id_lider_casa_paz : null,
  );
  const [liderCelula, setLiderCelula] = useState<IDiscipuloSelect>(
    recordForEdit
      ? { id: recordForEdit.id_lider_celula, title: '' }
      : { id: '', title: '' },
  );
  const [liderCelulaAnterior, setLiderCelulaAnterior] = useState(
    recordForEdit ? recordForEdit.id_lider_celula : null,
  );
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupCEP, setOpenPopupCEP] = useState(false);
  const [fileToCrop, setFileToCrop] = useState(null);

  const validate = (fieldValues: IValue = values): boolean | undefined => {
    const temp: IError = { ...errors };
    if ('nome' in fieldValues)
      temp.nome = fieldValues.nome ? '' : 'Campo obrigatório.';
    if ('sexo' in fieldValues)
      temp.sexo = fieldValues.sexo ? '' : 'Campo obrigatório.';
    if ('estado_civil' in fieldValues)
      temp.estado_civil = fieldValues.estado_civil ? '' : 'Campo obrigatório.';
    if ('celular' in fieldValues) {
      if (fieldValues.celular) {
        const celularField = fieldValues.celular
          .replace('(', '')
          .replace(')', '')
          .replace('-', '')
          .replace(' ', '');
        temp.celular = '';
        if (celularField.length !== 11) {
          temp.celular = 'Campo incompleto';
        } else if (celularField.length === 11) {
          if (!validarCelular(celularField)) {
            temp.celular = 'Celular inválido';
          }
        }
      } else {
        temp.celular = 'Campo obrigatório.';
      }
    }
    if ('cep' in fieldValues)
      temp.cep = fieldValues.cep ? '' : 'Campo obrigatório.';
    if ('endereco' in fieldValues)
      temp.endereco = fieldValues.endereco ? '' : 'Campo obrigatório.';
    if ('numero' in fieldValues)
      temp.numero = fieldValues.numero ? '' : 'Campo obrigatório.';
    if ('bairro' in fieldValues)
      temp.bairro = fieldValues.bairro ? '' : 'Campo obrigatório.';
    if ('cidade' in fieldValues)
      temp.cidade = fieldValues.cidade ? '' : 'Campo obrigatório.';
    if ('estado' in fieldValues)
      temp.estado = fieldValues.estado ? '' : 'Campo obrigatório.';
    if ('data_nascimento' in fieldValues)
      temp.data_nascimento =
        fieldValues.data_nascimento === null ||
        fieldValues.data_nascimento === undefined
          ? 'Campo obrigatório.'
          : '';
    if ('data_batismo' in fieldValues)
      temp.data_batismo =
        fieldValues.data_batismo === null ||
        fieldValues.data_batismo === undefined
          ? 'Campo obrigatório.'
          : '';
    if ('religiao_veio' in fieldValues)
      temp.religiao_veio = fieldValues.religiao_veio
        ? ''
        : 'Campo obrigatório.';
    setErrors({
      ...temp,
    });

    if (fieldValues === values) return Object.values(temp).every(x => x === '');
    return undefined;
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    handleAutoCompleteChange,
    resetForm,
    handleMultipleChange,
  } = useForm(initialFValues, initialFErrors, true, validate);

  useEffect(() => {
    api
      .get(`/batizantes-sala/f0e6b1ed-053a-473a-8c1c-b02403dd7686`, {
        headers: {
          activity: encrypt('Cadastro de Batizantes'),
        },
      })
      .then(response => {
        const content = decrypt<IBatizante[]>(response.data);
        const batizantesCadastrados: string[] = [];
        const idBatizantesCadastrados: string[] = [];
        content.map(bt => {
          idBatizantesCadastrados.push(bt.id);
          const celularFormatted = bt.celular
            ? ` (${formatarCelular(bt.celular)})`
            : '';
          const conteudo = `${bt.nome + celularFormatted}`;
          batizantesCadastrados.push(conteudo);
          return null;
        });
        setBatizantes(batizantesCadastrados);
        setIdBatizantes(idBatizantesCadastrados);
      });
  }, []);

  useEffect(() => {
    if (recordForEdit !== null) {
      setValues({
        ...recordForEdit,
        celular: recordForEdit.celular || '',
        email: recordForEdit.email || '',
        cep: recordForEdit.cep || '',
        endereco: recordForEdit.endereco || '',
        numero: recordForEdit.numero || 0,
        complemento: recordForEdit.complemento || '',
        bairro: recordForEdit.bairro || '',
        cidade: recordForEdit.cidade || '',
        estado: recordForEdit.estado || '',
        estado_civil: recordForEdit.estado_civil || '',
        ibge: recordForEdit.ibge || '',
        lider_casa_paz: recordForEdit.lider_casa_paz || '',
        lider_celula: recordForEdit.lider_celula || '',
        nome_celula: recordForEdit.nome_celula || '',
        observacao: recordForEdit.observacao || '',
        nomes_batismo_conjunto: recordForEdit.nomes_batismo_conjunto || '',
        religiao_veio: recordForEdit.religiao_veio || '',
        modo_casa_paz: recordForEdit.modo_casa_paz || '',
        dia_horario: recordForEdit.dia_horario || '',
        nome_contato: recordForEdit.nome_contato || '',
        telefone_contato: recordForEdit.telefone_contato || '',
        facebook: recordForEdit.facebook || '',
        instagram: recordForEdit.instagram || '',
      });
    }
  }, [recordForEdit, setValues]);

  const callback = (image: any): any => {
    // Create a FormData and append the file with "image" as parameter name
    const data = new FormData();

    const fileName = `${recordForEdit?.id}.jpg`;
    data.append('foto', image, fileName);

    api
      .patch(`/batizantes/${recordForEdit?.id}/foto`, data)
      .then(response => {
        const content = decrypt<IValue>(response.data);
        setValues({
          ...content,
          data_nascimento: content.data_nascimento
            ? parse(
                content.data_nascimento.toString(),
                'yyyy-MM-dd',
                new Date(),
              )
            : undefined,
          data_batismo: content.data_batismo
            ? parse(content.data_batismo.toString(), 'yyyy-MM-dd', new Date())
            : undefined,
        });
        addToast({
          type: 'success',
          title: 'Foto atualizada!',
        });
        updateFoto(recordForEdit?.id, content.foto_url);
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: 'Erro ao atualizar a foto!',
          description: 'Ocorreu um erro ao tentar atualizar a foto.',
        });
      });
  };

  const onFileChange = useCallback(async e => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setFileToCrop(file);
      setOpenPopup(true);
    }
  }, []);

  const handleFotoChange = useCallback((imageCropped: string) => {
    if (imageCropped) {
      const ImageURL = imageCropped;
      const block = ImageURL.split(';');
      const contentType = block[0].split(':')[1];
      const realData = block[1].split(',')[1];
      // Convert it to a blob to upload
      const blob = b64toBlob(realData, contentType);
      resize(blob, callback);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      values.id_lider_casa_paz !== '' &&
      values.id_lider_casa_paz !== liderCasaPazAnterior
    ) {
      const liderCasaPazSelecionado = discipulos.find(
        d => d.id === values.id_lider_casa_paz,
      );
      if (liderCasaPazSelecionado) {
        setLiderCasaPaz(liderCasaPazSelecionado);
      }
      setLiderCasaPazAnterior(values.id_lider_casa_paz);
    } else if (liderCasaPaz.id === liderCasaPazAnterior && recordForEdit) {
      const liderCasaPazSelecionado = discipulos.find(
        c => c.id === liderCasaPaz.id,
      );
      if (liderCasaPazSelecionado) {
        setLiderCasaPaz(liderCasaPazSelecionado);
      }
    } else if (
      values.id_lider_casa_paz === '' &&
      (liderCasaPaz.id === '' || !recordForEdit)
    ) {
      setLiderCasaPaz({ id: '', title: '' });
    } else if (values.id_lider_casa_paz === '' && liderCasaPaz.id !== '') {
      setLiderCasaPaz({ id: '', title: '' });
      setLiderCasaPazAnterior(null);
    }
  }, [
    discipulos,
    values.id_lider_casa_paz,
    liderCasaPaz.id,
    liderCasaPazAnterior,
    recordForEdit,
  ]);

  useEffect(() => {
    if (
      values.id_lider_celula !== '' &&
      values.id_lider_celula !== liderCelulaAnterior
    ) {
      const liderCelulaSelecionado = discipulos.find(
        d => d.id === values.id_lider_celula,
      );
      if (liderCelulaSelecionado) {
        setLiderCelula(liderCelulaSelecionado);
      }
      setLiderCelulaAnterior(values.id_lider_celula);
    } else if (liderCelula.id === liderCelulaAnterior && recordForEdit) {
      const liderCelulaSelecionado = discipulos.find(
        c => c.id === liderCelula.id,
      );
      if (liderCelulaSelecionado) {
        setLiderCelula(liderCelulaSelecionado);
      }
    } else if (
      values.id_lider_celula === '' &&
      (liderCelula.id === '' || !recordForEdit)
    ) {
      setLiderCelula({ id: '', title: '' });
    } else if (values.id_lider_celula === '' && liderCelula.id !== '') {
      setLiderCelula({ id: '', title: '' });
      setLiderCelulaAnterior(null);
    }
  }, [
    discipulos,
    values.id_lider_celula,
    liderCelula.id,
    liderCelulaAnterior,
    recordForEdit,
  ]);

  const handleSubmit = (e: any): void => {
    e.preventDefault();
    if (validate()) {
      addOrEdit(values, resetForm, batizantes, idBatizantes);
    }
  };

  const handleLocalizarEndereco = (e: any): void => {
    const cepInput = e.target.value.replace('.', '').replace('-', '');
    if (cepInput.length === 8) {
      axios
        .get(`${process.env.REACT_APP_VIACEP_API_URL}/ws/${cepInput}/json/`)
        .then(
          response => {
            const dados = response.data;
            setValues({
              ...values,
              endereco: dados.logradouro,
              bairro: dados.bairro,
              cidade: dados.localidade,
              estado: dados.uf,
              ibge: dados.ibge,
            });
          },
          error => {
            console.log(error);
          },
        );
    }
  };

  const handleFindCEP = (cep: string): any => {
    const cepInput = cep.replace('.', '').replace('-', '');
    if (cepInput.length === 8) {
      axios
        .get(`${process.env.REACT_APP_VIACEP_API_URL}/ws/${cepInput}/json/`)
        .then(
          response => {
            const dados = response.data;
            setValues({
              ...values,
              cep: dados.cep,
              endereco: dados.logradouro,
              bairro: dados.bairro,
              cidade: dados.localidade,
              estado: dados.uf,
              ibge: dados.ibge,
            });
          },
          error => {
            console.log(error);
          },
        );
    }
    setOpenPopupCEP(false);
  };

  const handleOnError = (): void => {
    const fotoField = document.querySelector('#fotoField') as HTMLImageElement;
    if (fotoField) {
      fotoField.src = semImagem;
      fotoField.style.border = '1px solid #111';
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        {recordForEdit && (
          <Grid container>
            <Grid item xs={6}>
              <FotoPass>
                <FotoInput>
                  <img
                    id="fotoField"
                    src={values.foto_url}
                    alt={values.name}
                    onError={handleOnError}
                  />
                  <label htmlFor="foto">
                    <FiCamera />

                    <input
                      type="file"
                      id="foto"
                      onChange={onFileChange}
                      accept="image/*"
                    />
                  </label>
                </FotoInput>
              </FotoPass>
            </Grid>
          </Grid>
        )}
        <Grid container>
          <Grid item xs={12} sm={6} md={6}>
            <Controls.Input
              name="nome"
              label="Nome"
              placeholder="Nome completo"
              value={values.nome}
              onChange={handleInputChange}
              error={errors.nome}
            />
            <Controls.Select
              name="sexo"
              label="Sexo"
              value={values.sexo}
              options={[
                { id: 'masculino', title: 'Masculino' },
                { id: 'feminino', title: 'Feminino' },
              ]}
              onChange={handleInputChange}
              error={errors.sexo}
            />
            <Controls.DatePicker
              name="data_nascimento"
              label="Data de Nascimento"
              format="dd/MM/yyyy"
              value={values.data_nascimento ? values.data_nascimento : null}
              onChange={handleInputChange}
              error={errors.data_nascimento}
            />
            <InputMask
              mask="(99) 99999-9999"
              value={values.celular}
              onChange={handleInputChange}
            >
              {(inputProps: any) => (
                <Controls.Input
                  {...inputProps}
                  name="celular"
                  label="Celular"
                  placeholder="Celular"
                  error={errors.celular}
                  type="cep"
                />
              )}
            </InputMask>
            <Controls.Input
              name="email"
              label="E-mail"
              placeholder="E-mail"
              value={values.email}
              onChange={handleInputChange}
              error={errors.email}
            />
            <Controls.Select
              name="estado_civil"
              label="Estado Civil"
              value={values.estado_civil}
              options={[
                { id: 'casado', title: 'Casado(a)' },
                { id: 'solteiro', title: 'Solteiro(a)' },
                { id: 'amasiado', title: 'Amasiado(a)' },
                { id: 'divorciado', title: 'Divorciado(a)' },
                { id: 'viuvo', title: 'Viúvo(a)' },
              ]}
              onChange={handleInputChange}
              error={errors.estado_civil}
            />
            <Controls.DatePicker
              name="data_batismo"
              label="Data do Batismo"
              format="dd/MM/yyyy"
              value={values.data_batismo ? values.data_batismo : null}
              onChange={handleInputChange}
              error={errors.data_batismo}
            />
            <Controls.Input
              name="nome_contato"
              label="Nome Recado"
              placeholder="Nome completo recado"
              value={values.nome_contato}
              onChange={handleInputChange}
            />
            <InputMask
              mask="(99) 99999-9999"
              value={values.telefone_contato}
              onChange={handleInputChange}
            >
              {(inputProps: any) => (
                <Controls.Input
                  {...inputProps}
                  name="telefone_contato"
                  label="Telefone Recado"
                  placeholder="Telefone recado"
                  type="cep"
                />
              )}
            </InputMask>
            <CEPField>
              <InputMask
                mask="99999-999"
                value={values.cep}
                onChange={handleInputChange}
                onBlur={handleLocalizarEndereco}
              >
                {(inputProps: any) => (
                  <Controls.Input
                    {...inputProps}
                    name="cep"
                    label="CEP"
                    placeholder="CEP"
                    error={errors.cep}
                    type="cep"
                    style={{ width: '110px' }}
                  />
                )}
              </InputMask>
              <Button
                type="button"
                className={classes.button}
                variant="contained"
                color="secondary"
                onClick={() => setOpenPopupCEP(true)}
              >
                Não sei
              </Button>
            </CEPField>
            <Controls.Input
              name="endereco"
              label="Endereço"
              placeholder="Endereço"
              value={values.endereco}
              onChange={handleInputChange}
              error={errors.endereco}
            />
            <Controls.Input
              name="numero"
              label="Número"
              placeholder="Número"
              value={values.numero}
              onChange={handleInputChange}
              error={errors.numero}
            />
            <Controls.Input
              name="complemento"
              label="Complemento"
              placeholder="Complemento"
              value={values.complemento}
              onChange={handleInputChange}
              error={errors.complemento}
            />
            <Controls.Input
              name="bairro"
              label="Bairro"
              placeholder="Bairro"
              value={values.bairro}
              onChange={handleInputChange}
              error={errors.bairro}
            />
            <Controls.Input
              name="cidade"
              label="Cidade"
              placeholder="Cidade"
              value={values.cidade}
              onChange={handleInputChange}
              error={errors.cidade}
            />
            <Controls.Input
              name="estado"
              label="Estado"
              placeholder="Estado"
              value={values.estado}
              onChange={handleInputChange}
              error={errors.estado}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controls.Input
              name="facebook"
              label="Facebook"
              placeholder="Endereço Facebook"
              value={values.facebook}
              onChange={handleInputChange}
            />
            <Controls.Input
              name="instagram"
              label="Instagram"
              placeholder="Endereço Instagram"
              value={values.instagram}
              onChange={handleInputChange}
            />
            <Controls.Input
              name="religiao_veio"
              label="Religião veio"
              placeholder="Religião veio"
              value={values.religiao_veio}
              onChange={handleInputChange}
              error={errors.religiao_veio}
            />
            <Controls.Checkbox
              name="batismo_conjunto"
              label="Batismo conjunto?"
              value={values.batismo_conjunto}
              onChange={handleInputChange}
            />
            <Controls.Input
              name="nomes_batismo_conjunto"
              label="Nomes (parentesco)"
              placeholder="Nomes completos (parentesco)"
              value={values.nomes_batismo_conjunto}
              onChange={handleInputChange}
            />
            <FormControl className={classes.formControl}>
              <InputLabel>Batizantes juntos</InputLabel>
              <Select
                name="batizantes_juntos"
                multiple
                value={
                  values.batizantes_juntos && values.batizantes_juntos !== ''
                    ? values.batizantes_juntos?.trim().split(',')
                    : []
                }
                onChange={handleMultipleChange}
                input={<Input />}
                renderValue={selected => (
                  <div className={classes.chips}>
                    {(selected as string[]).map(value => (
                      <Chip
                        key={value}
                        label={value}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {batizantes.map(batizante => (
                  <MenuItem
                    key={batizante}
                    value={batizante}
                    style={getStyles(
                      batizante,
                      values.batizantes_juntos &&
                        values.batizantes_juntos !== ''
                        ? values.batizantes_juntos?.trim().split(',')
                        : [],
                      theme,
                    )}
                  >
                    {batizante}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* <Controls.Checkbox
              name="fez_casa_paz"
              label="Fez Casa de Paz?"
              value={values.fez_casa_paz}
              onChange={handleInputChange}
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Controls.AutoComplete
                name="id_lider_casa_paz"
                label="Lider Casa de Paz"
                value={liderCasaPaz}
                disabled={false}
                onChange={handleAutoCompleteChange}
                options={discipulos}
                loading={false}
                loadingText="Carregando..."
                fullWidth
              />
              <Fade
                in={false}
                style={{
                  transitionDelay: '0ms',
                }}
                unmountOnExit
              >
                <CircularProgress />
              </Fade>
            </div>
            <Controls.Input
              name="lider_casa_paz"
              label="Lider da Casa de Paz"
              placeholder="Lider da Casa de Paz"
              value={values.lider_casa_paz}
              onChange={handleInputChange}
            /> */}

            <Controls.Checkbox
              name="frequenta_celula"
              label="Frequenta alguma Célula?"
              value={values.frequenta_celula}
              onChange={handleInputChange}
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Controls.AutoComplete
                name="id_lider_celula"
                label="Lider Célula"
                value={liderCelula}
                disabled={false}
                onChange={handleAutoCompleteChange}
                options={discipulos}
                loading={false}
                loadingText="Carregando..."
                fullWidth
              />
              <Fade
                in={false}
                style={{
                  transitionDelay: '0ms',
                }}
                unmountOnExit
              >
                <CircularProgress />
              </Fade>
            </div>
            <Controls.Input
              name="lider_celula"
              label="Lider da Célula"
              placeholder="Lider da Célula"
              value={values.lider_celula}
              onChange={handleInputChange}
            />
            <Controls.Input
              name="nome_celula"
              label="Nome da Célula"
              placeholder="Nome da Célula"
              value={values.nome_celula}
              onChange={handleInputChange}
            />

            {/* <div style={{ marginTop: '10px' }}>
              <Controls.RadioGroup
                id="modo_casa_paz"
                name="modo_casa_paz"
                label="Modo Casa Paz"
                onChange={handleInputChange}
                value={values.modo_casa_paz}
                items={modos}
                error={errors.modo_casa_paz}
              />
            </div>
            <div style={{ marginTop: '10px' }}>
              <Controls.RadioGroup
                id="dia_horario"
                name="dia_horario"
                label="Dia/Horário"
                onChange={handleInputChange}
                value={values.dia_horario}
                items={dias_horarios}
                error={errors.dia_horario}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Controls.Checkbox
                name="domingo_manha"
                label="Dom (manhã)"
                value={values.domingo_manha}
                onChange={handleInputChange}
                disabled
              />
              <Controls.Checkbox
                name="domingo_tarde"
                label="Dom (tarde)"
                value={values.domingo_tarde}
                onChange={handleInputChange}
                disabled
              />
              <Controls.Checkbox
                name="domingo_noite"
                label="Dom (noite)"
                value={values.domingo_noite}
                onChange={handleInputChange}
                disabled
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Controls.Checkbox
                name="segunda_manha"
                label="Seg (manhã)"
                value={values.segunda_manha}
                onChange={handleInputChange}
              />
              <Controls.Checkbox
                name="segunda_tarde"
                label="Seg (tarde)"
                value={values.segunda_tarde}
                onChange={handleInputChange}
              />
              <Controls.Checkbox
                name="segunda_noite"
                label="Seg (noite)"
                value={values.segunda_noite}
                onChange={handleInputChange}
                disabled
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Controls.Checkbox
                name="terca_manha"
                label="Ter (manhã)"
                value={values.terca_manha}
                onChange={handleInputChange}
              />
              <Controls.Checkbox
                name="terca_tarde"
                label="Ter (tarde)"
                value={values.terca_tarde}
                onChange={handleInputChange}
              />
              <Controls.Checkbox
                name="terca_noite"
                label="Ter (noite)"
                value={values.terca_noite}
                onChange={handleInputChange}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Controls.Checkbox
                name="quarta_manha"
                label="Qua (manhã)"
                value={values.quarta_manha}
                onChange={handleInputChange}
              />
              <Controls.Checkbox
                name="quarta_tarde"
                label="Qua (tarde)"
                value={values.quarta_tarde}
                onChange={handleInputChange}
              />
              <Controls.Checkbox
                name="quarta_noite"
                label="Qua (noite)"
                value={values.quarta_noite}
                onChange={handleInputChange}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Controls.Checkbox
                name="quinta_manha"
                label="Qui (manhã)"
                value={values.quinta_manha}
                onChange={handleInputChange}
              />
              <Controls.Checkbox
                name="quinta_tarde"
                label="Qui (tarde)"
                value={values.quinta_tarde}
                onChange={handleInputChange}
              />
              <Controls.Checkbox
                name="quinta_noite"
                label="Qui (noite)"
                value={values.quinta_noite}
                onChange={handleInputChange}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Controls.Checkbox
                name="sexta_manha"
                label="Sex (manhã)"
                value={values.sexta_manha}
                onChange={handleInputChange}
              />
              <Controls.Checkbox
                name="sexta_tarde"
                label="Sex (tarde)"
                value={values.sexta_tarde}
                onChange={handleInputChange}
              />
              <Controls.Checkbox
                name="sexta_noite"
                label="Sex (noite)"
                value={values.sexta_noite}
                onChange={handleInputChange}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Controls.Checkbox
                name="sexta_manha"
                label="Sex (manhã)"
                value={values.sexta_manha}
                onChange={handleInputChange}
              />
              <Controls.Checkbox
                name="sexta_tarde"
                label="Sex (tarde)"
                value={values.sexta_tarde}
                onChange={handleInputChange}
              />
              <Controls.Checkbox
                name="sexta_noite"
                label="Sex (noite)"
                value={values.sexta_noite}
                onChange={handleInputChange}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Controls.Checkbox
                name="sabado_manha"
                label="Sáb (manhã)"
                value={values.sabado_manha}
                onChange={handleInputChange}
              />
              <Controls.Checkbox
                name="sabado_tarde"
                label="Sáb (tarde)"
                value={values.sabado_tarde}
                onChange={handleInputChange}
              />
              <Controls.Checkbox
                name="sabado_noite"
                label="Sáb (noite)"
                value={values.sabado_noite}
                onChange={handleInputChange}
              />
            </div> */}
            <Controls.Input
              name="observacao"
              label="Observação"
              multiline
              placeholder="Observação"
              value={values.observacao}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
        <div>
          <Button type="submit" className={classes.buttonForm}>
            Salvar
          </Button>
          {!recordForEdit && (
            <Button color="default" onClick={resetForm}>
              Limpar campos
            </Button>
          )}
        </div>
      </Form>
      <Popup
        title="Recorte de foto"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <CropImage fileToCrop={fileToCrop} onCropped={handleFotoChange} />
      </Popup>
      <Popup
        title="Localizar CEP"
        openPopup={openPopupCEP}
        setOpenPopup={setOpenPopupCEP}
      >
        <CEPForm
          addOrEdit={handleFindCEP}
          estados={estados}
          cidades={cidades}
        />
      </Popup>
    </>
  );
};

export default BatizanteForm;
