import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';

import ProtectedRoute from './Route';

import MainPage from '../pages/MainPage';
import SignIn from '../pages/SignIn';
import EsqueciSenha from '../pages/Usuario/EsqueciSenha';
import ResetarSenha from '../pages/Usuario/ResetarSenha';
import GeradorQrCodePage from '../pages/MainPage/GeradorQrCodePage';
import BatizantePreCadastroPage from '../pages/MainPage/BatizantePreCadastroPage';
import BatizanteSalaPage from '../pages/MainPage/BatizanteSalaPage';
import BatizanteSalaPrePage from '../pages/MainPage/BatizanteSalaPrePage';
import BatizanteComplementoPage from '../pages/MainPage/BatizanteComplementoPage';

const HomePage = lazy(() => import('../pages/HomePage'));
const PrestadorServicoPage = lazy(
  () => import('../pages/MainPage/PrestadorServicoPage'),
);
const ParametrosGeraisPage = lazy(
  () => import('../pages/Parametrizacoes/ParametrosGeraisPage'),
);
const CarteirinhaPage = lazy(
  () => import('../pages/Discipulo/CarteirinhaPage'),
);
const VisitantePage = lazy(() => import('../pages/Cadastros/VisitantePage'));
const MenuPage = lazy(() => import('../pages/Administracao/MenuPage'));
const SubMenuPage = lazy(() => import('../pages/Administracao/SubMenuPage'));
const GrupoPage = lazy(() => import('../pages/Administracao/GrupoPage'));
const UsuarioGrupoPage = lazy(
  () => import('../pages/Administracao/UsuarioGrupoPage'),
);
const GrupoPermissaoPage = lazy(
  () => import('../pages/Administracao/GrupoPermissaoPage'),
);
const ConselhoEticaDenunciaPage = lazy(
  () => import('../pages/ConselhoEtica/DenunciaPage'),
);
const UsuarioPage = lazy(() => import('../pages/Administracao/UsuarioPage'));
const QrCodeScanPage = lazy(() => import('../pages/QrCodeScanPage'));
const VideoPlayerPage = lazy(
  () => import('../pages/Discipulo/VideoPlayerPage'),
);
const DiscipuloQRCodePage = lazy(() => import('../pages/Discipulo/QRCodePage'));
const DiscipulosQRCodePage = lazy(
  () => import('../pages/Cadastros/QRCodeDiscipulosPage'),
);
const DiscipuloComprovantePage = lazy(
  () => import('../pages/Discipulo/ComprovantePage'),
);
const DiscipuloPage = lazy(() => import('../pages/Discipulo/DiscipuloPage'));
const DiscipuloEscalaPage = lazy(() => import('../pages/Discipulo/EscalaPage'));
const CadastroDiscipuloPage = lazy(
  () => import('../pages/Cadastros/DiscipuloPage'),
);
const CadastroBatismoPage = lazy(() => import('../pages/Batismo/BatismoPage'));
const CadastroBatizantePage = lazy(
  () => import('../pages/Batismo/BatizantePage'),
);
const CheckinBatismoPage = lazy(() => import('../pages/Batismo/CheckinPage'));
const CheckinBatismoTotenPage = lazy(
  () => import('../pages/Batismo/CheckinTotenPage'),
);
const CheckinBatismoManualPage = lazy(
  () => import('../pages/Batismo/CheckinManualPage'),
);
const ListaPresencaBatismoPage = lazy(
  () => import('../pages/Batismo/ListaPresencaPage'),
);
// Bem Cuidadas
const CadastroBemCuidadaPage = lazy(
  () => import('../pages/BemCuidadas/BemCuidadaPage'),
);
const EventoBemCuidadasPage = lazy(
  () => import('../pages/BemCuidadas/EventoPage'),
);
const CheckinBemCuidadasPage = lazy(
  () => import('../pages/BemCuidadas/CheckinPage'),
);
const CheckinBemCuidadasTotenPage = lazy(
  () => import('../pages/BemCuidadas/CheckinTotenPage'),
);
const CheckinBemCuidadasManualPage = lazy(
  () => import('../pages/BemCuidadas/CheckinManualPage'),
);
const ListaPresencaBemCuidadasPage = lazy(
  () => import('../pages/BemCuidadas/ListaPresencaPage'),
);

const EventoPage = lazy(() => import('../pages/Evento/EventoPage'));
const DepartamentoPage = lazy(
  () => import('../pages/Cadastros/DepartamentoPage'),
);
const LiderPage = lazy(() => import('../pages/Cadastros/LiderPage'));
const DepartamentoEscalaPage = lazy(
  () => import('../pages/Departamento/EscalaPage'),
);
const SolicitacaoLinkPage = lazy(
  () => import('../pages/Departamento/SolicitacaoLinkPage'),
);
const CredenciamentoPage = lazy(
  () => import('../pages/Evento/CredenciamentoPage'),
);
const CheckinPage = lazy(() => import('../pages/Evento/CheckinPage'));
const CheckinTotenPage = lazy(() => import('../pages/Evento/CheckinTotenPage'));
const CheckinNFCPage = lazy(() => import('../pages/Evento/CheckinNFCPage'));
const CheckinHibridoPage = lazy(
  () => import('../pages/Evento/CheckinHibridoPage'),
);
const CheckinManualPage = lazy(
  () => import('../pages/Evento/CheckinManualPage'),
);
const CheckinManualCentralPage = lazy(
  () => import('../pages/Evento/CheckinManualCentralPage'),
);
const PainelPage = lazy(() => import('../pages/Evento/PainelPage'));
const ListaPresencaEscolaSabedoriaPage = lazy(
  () => import('../pages/Relatorios/ListaPresencaEscolaSabedoriaPage'),
);
const ListaPresencaReuniaoPage = lazy(
  () => import('../pages/Relatorios/ListaPresencaReuniaoPage'),
);
const ListaPresencaPage = lazy(
  () => import('../pages/Departamento/ListaPresencaPage'),
);
const ListaPresencaGeralPage = lazy(
  () => import('../pages/Departamento/ListaPresencaGeralPage'),
);
const SituacaoLiderDepartamentoGeralPage = lazy(
  () => import('../pages/Departamento/SituacaoLiderDepartamentoPage'),
);
const SituacaoLiderDepartamentoGeralReuniaoPage = lazy(
  () => import('../pages/Departamento/SituacaoLiderDepartamentoReuniaoPage'),
);
const SituacaoLiderDepartamentoPage = lazy(
  () => import('../pages/Departamento/SituacaoLiderPage'),
);
const SituacaoLiderReuniaoDepartamentoPage = lazy(
  () => import('../pages/Departamento/SituacaoLiderReuniaoPage'),
);
const SituacaoLiderGeralPage = lazy(
  () => import('../pages/Departamento/SituacaoLiderGeralPage'),
);
const SituacaoLiderGeralReuniaoPage = lazy(
  () => import('../pages/Departamento/SituacaoLiderGeralReuniaoPage'),
);
const CelulaPage = lazy(() => import('../pages/Celula/CelulaPage'));
const CelulaMapaPage = lazy(() => import('../pages/Celula/MapaPage'));
const LocalizadorCelulaPage = lazy(
  () => import('../pages/MainPage/LocalizadorPage'),
);
const TriagemPage = lazy(() => import('../pages/Celula/TriagemPage'));
const TriagemBatizantePage = lazy(
  () => import('../pages/Celula/BatizanteTriagemPage'),
);
const CelulaLocalizadorPage = lazy(
  () => import('../pages/Celula/LocalizadorPage'),
);
const SupervisaoCelulaPage = lazy(
  () => import('../pages/Celula/SupervisaoCelulaPage'),
);
const CoordenacaoCelulaPage = lazy(
  () => import('../pages/Celula/CoordenacaoCelulaPage'),
);
const CasaPazPage = lazy(() => import('../pages/CasaPaz/CasaPazPage'));
const CasaPazBatizantePage = lazy(
  () => import('../pages/CasaPaz/BatizantePage'),
);
const ContabilidadeComprovantePage = lazy(
  () => import('../pages/Contabilidade/ComprovantePage'),
);
const AltarPregadorPage = lazy(() => import('../pages/Altar/PregadorPage'));
const AltarAberturaPage = lazy(() => import('../pages/Altar/AberturaPage'));
const AltarMeioPage = lazy(() => import('../pages/Altar/MeioPage'));
const AltarEscalaPage = lazy(() => import('../pages/Altar/EscalaPage'));
const EtiquetaPage = lazy(() => import('../pages/Evento/EtiquetaPage'));

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={MainPage} />
    <Route path="/index.html" component={MainPage} />
    <Route path="/obter-qrcode" exact component={GeradorQrCodePage} />
    <Route path="/batizantes-pre" exact component={BatizantePreCadastroPage} />
    <Route path="/batizantes-sala" exact component={BatizanteSalaPage} />
    <Route path="/batizantes-sala-pre" exact component={BatizanteSalaPrePage} />
    <Route
      path="/batizantes-complemento"
      exact
      component={BatizanteComplementoPage}
    />
    <Route path="/localizador" component={LocalizadorCelulaPage} />
    <Route path="/prestador-servico" component={PrestadorServicoPage} />
    <ProtectedRoute path="/signin" component={SignIn} />
    <ProtectedRoute path="/esqueci-senha" component={EsqueciSenha} />
    <ProtectedRoute path="/resetar-senha" component={ResetarSenha} />
    <ProtectedRoute
      path="/grupo"
      component={GrupoPage}
      isPrivate
      s="e0088523-f072-4060-9805-9d75758b927f"
    />
    <ProtectedRoute
      path="/menu"
      component={MenuPage}
      isPrivate
      s="9fd323c8-b9e6-49b2-aecf-da3d1b481727"
    />
    <ProtectedRoute
      path="/sub-menu"
      component={SubMenuPage}
      isPrivate
      s="c40bb499-b86f-4e20-9806-7462d1f8d068"
    />
    <ProtectedRoute
      path="/grupo-permissao"
      component={GrupoPermissaoPage}
      isPrivate
      s="9cd8e300-d75b-4c92-b837-fedb647632f7"
    />
    <ProtectedRoute
      path="/usuario-grupo"
      component={UsuarioGrupoPage}
      isPrivate
      s="c414e0ad-ef09-4149-b327-2288daa54a64"
    />
    <ProtectedRoute
      path="/usuario"
      component={UsuarioPage}
      isPrivate
      s="79cdb519-20af-4844-b0d9-1b74602a6e4a"
    />
    <ProtectedRoute
      path="/batismo"
      component={CadastroBatismoPage}
      isPrivate
      s="09789c6d-9e2a-44a8-9249-85016fb5a377"
    />
    <ProtectedRoute
      path="/batizante"
      component={CadastroBatizantePage}
      isPrivate
      s="c36dabb6-eeb8-4bd7-867e-9cb38c21b600"
    />
    <ProtectedRoute
      path="/checkin-batismo-qrcode"
      component={CheckinBatismoPage}
      isPrivate
      s="b7984f0b-2094-4b89-bb59-b1b42d314413"
    />
    <ProtectedRoute
      path="/checkin-batismo-toten"
      component={CheckinBatismoTotenPage}
      isPrivate
      s="18a9b802-437a-49c8-8514-0c711f878a2e"
    />
    <ProtectedRoute
      path="/checkin-batismo-manual"
      component={CheckinBatismoManualPage}
      isPrivate
      s="381e5fa5-a0bf-4693-ab45-6132b24b631a"
    />
    <ProtectedRoute
      path="/lista-presenca-batismo"
      component={ListaPresencaBatismoPage}
      isPrivate
      s="1a142e52-3530-4998-81b3-fb2658e9c167"
    />
    <ProtectedRoute
      path="/bem-cuidada"
      component={CadastroBemCuidadaPage}
      isPrivate
      s="b61789e2-97df-41e4-8e36-21bfd05c5f38"
    />
    <ProtectedRoute
      path="/evento-bem-cuidadas"
      component={EventoBemCuidadasPage}
      isPrivate
      s="f8a77e52-aebd-4ff3-be1c-e72282b56dc8"
    />
    <ProtectedRoute
      path="/checkin-bem-cuidadas-qrcode"
      component={CheckinBemCuidadasPage}
      isPrivate
      s="14c73ee0-c7d8-439e-89f1-c923e00acdbb"
    />
    <ProtectedRoute
      path="/checkin-bem-cuidadas-toten"
      component={CheckinBemCuidadasTotenPage}
      isPrivate
      s="7b0e7325-6232-4361-bb67-93b3f182506f"
    />
    <ProtectedRoute
      path="/checkin-bem-cuidadas-manual"
      component={CheckinBemCuidadasManualPage}
      isPrivate
      s="2d7dddf3-7bcd-4f15-bdab-5966e9a26b37"
    />
    <ProtectedRoute
      path="/lista-presenca-bem-cuidadas"
      component={ListaPresencaBemCuidadasPage}
      isPrivate
      s="b4558d90-380c-4fd4-a941-1b9ecb38a4e3"
    />
    <ProtectedRoute
      path="/departamento"
      component={DepartamentoPage}
      isPrivate
      s="1ce7645f-debf-44f3-892c-d519b2d686a8"
    />
    <ProtectedRoute
      path="/discipulo"
      component={CadastroDiscipuloPage}
      isPrivate
      s="0ab7c718-aed3-452b-b0f8-f152603f0b1b"
    />
    <ProtectedRoute
      path="/visitante"
      component={VisitantePage}
      isPrivate
      s="b570a88f-e943-4aa8-8074-a17227ec2b1b"
    />
    <ProtectedRoute
      path="/lideranca"
      component={LiderPage}
      isPrivate
      s="92471a59-2e37-4df1-bcf9-864872ce5a7f"
    />
    <ProtectedRoute
      path="/casa-paz"
      component={CasaPazPage}
      isPrivate
      s="cdc69ad1-7d3c-4d44-9fa7-57c3791c8885"
    />
    <ProtectedRoute
      path="/casa-paz-batizante"
      component={CasaPazBatizantePage}
      isPrivate
      s="b61a2ac8-0d43-4c2d-b94c-3f2204bb9f2f"
    />
    <ProtectedRoute
      path="/celula"
      component={CelulaPage}
      isPrivate
      s="b260dccd-0d82-4979-b2f7-abff7d514bc9"
    />
    <ProtectedRoute
      path="/celula-coordenacao"
      component={CoordenacaoCelulaPage}
      isPrivate
      s="8e060229-1ec9-4478-84c4-0a136c859c59"
    />
    <ProtectedRoute
      path="/celula-supervisao"
      component={SupervisaoCelulaPage}
      isPrivate
      s="e0992635-596d-465b-9013-24d2b12c16a3"
    />
    <ProtectedRoute
      path="/celula-localizador"
      component={CelulaLocalizadorPage}
      isPrivate
      s="4e33b853-43b5-463c-a69b-cd3aea4a1bed"
    />
    <ProtectedRoute
      path="/celula-mapa"
      component={CelulaMapaPage}
      isPrivate
      s="86faeb8c-21ba-4a02-8a56-3c73937fb934"
    />
    <ProtectedRoute
      path="/triagem"
      component={TriagemPage}
      isPrivate
      s="f52c4400-1a44-4e3b-947b-d6a0f4e081da"
    />
    <ProtectedRoute
      path="/triagem-batizante"
      component={TriagemBatizantePage}
      isPrivate
      s="509e45a1-9fb8-422a-87d5-b31c31cc6c2a"
    />
    <ProtectedRoute
      path="/denuncia-conselho-etica"
      component={ConselhoEticaDenunciaPage}
      isPrivate
      s="f1174a17-2018-4bfc-90dd-b213d687e31a"
    />
    <ProtectedRoute
      path="/escala-departamento"
      component={DepartamentoEscalaPage}
      isPrivate
      s="d3f6f4fe-9cf7-4d65-b514-c5b99d9a7e72"
    />
    <ProtectedRoute
      path="/contabilidade-comprovante"
      component={ContabilidadeComprovantePage}
      isPrivate
      s="24b0be35-cdd3-4e77-b869-606ef904cb1d"
    />
    <ProtectedRoute
      path="/discipulo-comprovante"
      component={DiscipuloComprovantePage}
      isPrivate
      s="0b7555b2-cbd9-4cd3-911a-5ee5cb51c9ee"
    />
    <ProtectedRoute
      path="/parametros-gerais"
      component={ParametrosGeraisPage}
      isPrivate
      s="e92e0fab-7150-4c4c-9fa7-4330ecd5e3b3"
    />
    <ProtectedRoute
      path="/carteirinha"
      component={CarteirinhaPage}
      isDiscipulo
      isPrivate
      s=""
    />
    <ProtectedRoute
      path="/videoplayer"
      component={VideoPlayerPage}
      isPrivate
      s=""
    />
    <ProtectedRoute
      path="/perfil-discipulo"
      component={DiscipuloPage}
      isDiscipulo
      isPrivate
      s=""
    />
    <ProtectedRoute
      path="/qrcode"
      component={DiscipuloQRCodePage}
      isDiscipulo
      isPrivate
      s=""
    />
    <ProtectedRoute
      path="/discipulos-qr-code"
      component={DiscipulosQRCodePage}
      isDiscipulo
      isPrivate
      s=""
    />
    <ProtectedRoute
      path="/escala-discipulo"
      component={DiscipuloEscalaPage}
      isPrivate
      s="25b3f4f6-88c6-4fb0-9c2e-36e7675ccdf1"
    />
    <ProtectedRoute
      path="/solicitacao-link"
      component={SolicitacaoLinkPage}
      isPrivate
      s=""
    />
    <ProtectedRoute
      path="/evento"
      component={EventoPage}
      isPrivate
      s="e8a90e4d-b789-4012-945e-cab7fe9ee498"
    />
    <ProtectedRoute
      path="/etiqueta"
      component={EtiquetaPage}
      isPrivate
      s="f58c79a7-aae1-4960-b29c-0d2d12b3c219"
    />
    <ProtectedRoute
      path="/checkin-qrcode"
      component={CheckinPage}
      isPrivate
      s="43a43243-a6d0-4329-800a-9987538dc293"
    />
    <ProtectedRoute
      path="/checkin-toten"
      component={CheckinTotenPage}
      isPrivate
      s="18029c35-1046-4c38-a1fc-3870d32301ec"
    />
    <ProtectedRoute
      path="/checkin-nfc"
      component={CheckinNFCPage}
      isPrivate
      s="4d50c1a1-c48f-4224-8720-1a55ff49ec96"
    />
    <ProtectedRoute
      path="/checkin-hibrido"
      component={CheckinHibridoPage}
      isPrivate
      s="4f0e8ac2-ab4f-437d-ac31-704578a3fe5c"
    />
    <ProtectedRoute
      path="/checkin-manual"
      component={CheckinManualPage}
      isPrivate
      s="2f955fdc-8563-4680-976c-9725e9d22f34"
    />
    <ProtectedRoute
      path="/checkin-manual-central"
      component={CheckinManualCentralPage}
      isPrivate
      s="efc0bd2f-b2ac-48db-89fe-c4d5c39d1817"
    />
    <ProtectedRoute
      path="/lista-presenca"
      component={ListaPresencaPage}
      isPrivate
      s=""
    />
    <ProtectedRoute
      path="/lista-presenca-geral"
      component={ListaPresencaGeralPage}
      isPrivate
      s="cccfa61e-8799-4bca-8d10-064d04e436e3"
    />
    <ProtectedRoute
      path="/situacao-lider-departamento"
      component={SituacaoLiderDepartamentoPage}
      isPrivate
      s="664f5ff3-da79-42cb-89ae-df63f8af66b9"
    />
    <ProtectedRoute
      path="/situacao-lider-departamento-reuniao"
      component={SituacaoLiderReuniaoDepartamentoPage}
      isPrivate
      s="0804953e-629d-47b4-b500-e1d6c8f2514e"
    />
    <ProtectedRoute
      path="/situacao-lider-departamento-geral"
      component={SituacaoLiderDepartamentoGeralPage}
      isPrivate
      s="17863502-243f-4b1c-9370-b42f12d3b0c6"
    />
    <ProtectedRoute
      path="/situacao-lider-departamento-geral-reuniao"
      component={SituacaoLiderDepartamentoGeralReuniaoPage}
      isPrivate
      s="a719c96c-eda7-42fd-9b33-3225fb49377c"
    />
    <ProtectedRoute
      path="/situacao-lider-geral"
      component={SituacaoLiderGeralPage}
      isPrivate
      s="2fe47382-7ae1-4e73-8b5f-237f6c10ed57"
    />
    <ProtectedRoute
      path="/situacao-lider-geral-reuniao"
      component={SituacaoLiderGeralReuniaoPage}
      isPrivate
      s="07bfeecb-11ff-49d3-be5a-8e0654e697fd"
    />
    <ProtectedRoute
      path="/painel"
      component={PainelPage}
      isPrivate
      s="2b4a343d-2c8c-46b8-9ecd-8320011a42c2"
    />
    <ProtectedRoute
      path="/lista-presenca-escola-sabedoria"
      component={ListaPresencaEscolaSabedoriaPage}
      isPrivate
      s="3723893e-390f-476d-856c-70774feeb6e0"
    />
    <ProtectedRoute
      path="/lista-presenca-reuniao"
      component={ListaPresencaReuniaoPage}
      isPrivate
      s=""
    />
    <ProtectedRoute
      path="/credenciamento"
      component={CredenciamentoPage}
      isPrivate
      s="9d5ce16c-c4c4-4de6-9f0e-e2b18c633f51"
    />
    <ProtectedRoute
      path="/scanner"
      component={QrCodeScanPage}
      isPrivate
      s="9e96f39b-07c9-480b-bd9e-8fa0ca4fc149"
    />
    <ProtectedRoute
      path="/altar-pregador"
      component={AltarPregadorPage}
      isPrivate
      s="ecaf56a9-5f6c-4b1d-9300-df54496f8d4d"
    />
    <ProtectedRoute
      path="/altar-abertura"
      component={AltarAberturaPage}
      isPrivate
      s="4abbb873-35da-441d-b16d-904f17108761"
    />
    <ProtectedRoute
      path="/altar-meio"
      component={AltarMeioPage}
      isPrivate
      s="56202787-72ec-4b51-a395-0601b8285321"
    />
    <ProtectedRoute
      path="/altar-escala"
      component={AltarEscalaPage}
      isPrivate
      s="4588254d-1db5-4828-8c82-46bd803a15c0"
    />
    <ProtectedRoute path="/home" component={HomePage} isPrivate s="" />
    <ProtectedRoute component={HomePage} isPrivate s="" />
  </Switch>
);

export default Routes;
