import { createGlobalStyle } from 'styled-components';

import './font.css';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  body {
    background: #ffffff;
    color: #000000;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    overflow-y: hidden;
    overflow: hidden;
  }

  body, input, button {
    /* font: 400 16px 'Roboto', sans-serif; */
    font-family: 'Roboto Slab', serif;
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  button {
    cursor: pointer;
  }

  video::media-controls-fullscreen {
    display: none !important;
  }

  video::-webkit-media-controls-fullscreen {
    display: none !important;
  }

  video::-ms-media-controls-fullscreen {
    display: none !important;
  }

  video::-webkit-media-controls-fullscreen-button {
    display: none !important;
  }

  video::-moz-media-controls-fullscreen-button {
    display: none !important;
  }

  video::-ms-media-controls-fullscreen-button {
    display: none !important;
  }

  video::media-controls-fullscreen-button {
    display: none !important;
  }

  video::-webkit-media-controls-play-button {
    display: none !important;
  }
`;
