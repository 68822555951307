import React from 'react';
import { Button, Grid } from '@material-ui/core';
import InputMask from 'react-input-mask';
import { useForm, Form } from '../../../components/UseForm/useForm.js';
import { useStyles } from './styles';
import Controls from '../../../components/controls/Controls.js';
import { decryptString } from '../../../services/crypt';

interface IValue {
  nome?: string;
  celular?: string;
}

interface IError {
  nome?: string;
  celular?: string;
}

let initialFValues = {
  nome: '',
  celular: '',
} as IValue;

const voluntarioStorage = localStorage.getItem(
  '@IEQLagoinha:voluntario_complemento',
);
if (voluntarioStorage) {
  const voluntarioArmazenado = decryptString(voluntarioStorage);
  const voluntarioData = JSON.parse(voluntarioArmazenado);
  if (voluntarioData) {
    initialFValues = {
      nome: voluntarioData.nome,
      celular: voluntarioData.celular,
    } as IValue;
  }
}

const initialFErrors = {
  nome: '',
  celular: '',
} as IError;

interface IVoluntarioFormProps {
  addVoluntario: any;
}

interface IVoluntario {
  nome: string;
  celular: string;
}

const VoluntarioForm: React.FC<IVoluntarioFormProps> = ({ addVoluntario }) => {
  const classes = useStyles();
  const validate = (fieldValues: IValue = values): boolean | undefined => {
    const temp: IError = { ...errors };
    if ('nome' in fieldValues)
      temp.nome = fieldValues.nome ? '' : 'Campo obrigatório.';
    if ('celular' in fieldValues)
      temp.celular = fieldValues.celular ? '' : 'Campo obrigatório.';

    setErrors({
      ...temp,
    });

    if (fieldValues === values) return Object.values(temp).every(x => x === '');
    return undefined;
  };

  const { values, errors, setErrors, handleInputChange, resetForm } = useForm(
    initialFValues,
    initialFErrors,
    true,
    validate,
  );

  const handleSubmit = (e: any): void => {
    e.preventDefault();
    if (validate()) {
      addVoluntario(values, resetForm);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12}>
          <Controls.Input
            name="nome"
            label="Nome"
            placeholder="Nome completo"
            value={values.nome}
            onChange={handleInputChange}
            error={errors.nome}
          />
          <InputMask
            mask="(99) 99999-9999"
            value={values.celular}
            onChange={handleInputChange}
          >
            {(inputProps: any) => (
              <Controls.Input
                {...inputProps}
                name="celular"
                label="Celular"
                placeholder="Celular"
                error={errors.celular}
                type="cep"
              />
            )}
          </InputMask>
        </Grid>
      </Grid>
      <div>
        <Button type="submit" className={classes.buttonForm}>
          Salvar
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            const url = `${process.env.REACT_APP_PUBLIC_URL}/batizantes-complemento`;
            window.location.href = url;
          }}
        >
          Cancelar
        </Button>
      </div>
    </Form>
  );
};

export default VoluntarioForm;
