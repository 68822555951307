import { makeStyles, Theme } from '@material-ui/core/styles';

interface Props {
  size: number;
  color: 'red' | 'blue' | string;
}

export const useStyles = makeStyles((theme: Theme) => ({
  box: (props: Props) => ({
    display: 'flex',
    alignItems: 'center',
    borderRadius: 8,
    height: props.size,
    width: props.size,
  }),
  text: {
    color: theme.palette.common.white,
  },
}));
