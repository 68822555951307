import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '900px',
    },
  },
  titleHead: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
}));
