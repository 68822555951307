import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/api';
import { decrypt } from '../services/crypt';
import getBase64FromUrl from '../utils/getBase64FromUrl';
import userMasculino from '../assets/user_masculino.png';
import userFeminino from '../assets/user_feminino.png';

interface IPermissions {
  id_sub_menu: string;
  servico: string;
  visivel: boolean;
}

interface IParametrosGerais {
  id_departamento_pastor?: string;
  id_departamento_celula?: string;
  id_departamento_casapaz?: string;
  id_departamento_homem?: string;
  id_departamento_mulher?: string;
  id_departamento_jovem?: string;
  id_departamento_adolescente?: string;
  id_departamento_infantil?: string;
  id_departamento_casal?: string;
  id_grupo_escala?: string;
  id_grupo_escala_pregador?: string;
}

interface IGetPermissions {
  permissoes: IPermissions[];
  parametros_gerais: IParametrosGerais;
  discipulo: any;
  foto: any;
  isAdmin: boolean;
  id_manager?: string;
  modulo: string;
  lider_celula: string[];
  lider_casapaz: string[];
  lideranca_celula: string[];
  facilitador_departamento: string[];
  coordenador_celula: string[];
  supervisor_celula: string[];
}

interface IPermissionContextData {
  permissions: IPermissions[];
  parametrosGerais: IParametrosGerais;
  discipulo: any;
  foto: any;
  isAdmin: boolean;
  idManager: string;
  moduloName: string;
  liderCelula: boolean;
  celulasLider: string[];
  liderCasaPaz: boolean;
  casasPazLider: string[];
  liderancaCelula: boolean;
  liderancasLider: string[];
  facilitadorDepartamento: boolean;
  facilitacoesLider: string[];
  coordenadorCelula: boolean;
  coordenacoesLider: string[];
  supervisorCelula: boolean;
  supervisoesLider: string[];
  getPermission(): Promise<void>;
  isAuthorized(id_sub_menu: string): boolean;
  resetPermissions(): void;
  setFotoDiscipulo(url: string): void;
  updateDiscipulo(discipuloUpdate: any): void;
  updateParametrosGerais(parametrosGeraisUpdate: IParametrosGerais): void;
}

const PermissionContext = createContext<IPermissionContextData>(
  {} as IPermissionContextData,
);

const PermissionProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<IPermissions[]>([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [idManager, setIdManager] = useState('');
  const [liderCelula, setLiderCelula] = useState(false);
  const [celulasLider, setCelulasLider] = useState<string[]>([]);
  const [liderCasaPaz, setLiderCasaPaz] = useState(false);
  const [casasPazLider, setCasasPazLider] = useState<string[]>([]);
  const [liderancaCelula, setLiderancaCelula] = useState(false);
  const [liderancasLider, setLiderancasLider] = useState<string[]>([]);
  const [facilitadorDepartamento, setFacilitadorDepartamento] = useState(false);
  const [facilitacoesLider, setFacilitacoesLider] = useState<string[]>([]);
  const [coordenadorCelula, setCoordenadorCelula] = useState(false);
  const [coordenacoesLider, setCoordenacoesLider] = useState<string[]>([]);
  const [supervisorCelula, setSupervisorCelula] = useState(false);
  const [supervisoesLider, setSupervisoesLider] = useState<string[]>([]);
  const [modulo, setModulo] = useState('');
  const [discipulo, setDiscipulo] = useState<any>(undefined);
  const [foto, setFoto] = useState<any>(undefined);
  const [parametrosGerais, setParametrosGerais] = useState<IParametrosGerais>(
    {},
  );

  const getPermission = useCallback(async () => {
    try {
      const response = await api.get(`permissoes`);
      const content = decrypt<IGetPermissions>(response.data);
      console.log(content);
      setData(content.permissoes);
      setIsAdmin(content.isAdmin);
      setIdManager(content.id_manager || '');
      setDiscipulo(content.discipulo);
      setModulo(content.modulo);
      setParametrosGerais(content.parametros_gerais);
      setLiderCelula(content.lider_celula.length > 0);
      setCelulasLider(content.lider_celula);
      setLiderCasaPaz(content.lider_casapaz.length > 0);
      setCasasPazLider(content.lider_casapaz);
      setLiderancaCelula(content.lideranca_celula.length > 0);
      setLiderancasLider(content.lideranca_celula);
      setFacilitadorDepartamento(content.facilitador_departamento.length > 0);
      setFacilitacoesLider(content.facilitador_departamento);
      setCoordenadorCelula(content.coordenador_celula.length > 0);
      setCoordenacoesLider(content.coordenador_celula);
      setSupervisorCelula(content.supervisor_celula.length > 0);
      setSupervisoesLider(content.supervisor_celula);
      if (content.discipulo && content.discipulo.foto_url) {
        getBase64FromUrl(content.discipulo.foto_url).then(image => {
          setFoto(image);
        });
      } else if (content.discipulo && content.discipulo.sexo) {
        if (content.discipulo.sexo === 'feminino') {
          setFoto(userFeminino);
        } else {
          setFoto(userMasculino);
        }
      } else {
        setFoto(userMasculino);
      }
    } catch (error) {
      // console.log('deu ruim', error);
      setData([]);
      setIsAdmin(false);
      setIdManager('');
      setModulo('');
      setDiscipulo(undefined);
      setParametrosGerais({});
      setLiderCelula(false);
      setCelulasLider([]);
      setLiderCasaPaz(false);
      setCasasPazLider([]);
      setLiderancaCelula(false);
      setLiderancasLider([]);
      setFacilitadorDepartamento(false);
      setFacilitacoesLider([]);
      setCoordenadorCelula(false);
      setCoordenacoesLider([]);
      setSupervisorCelula(false);
      setSupervisoesLider([]);
    }
  }, []);

  const isAuthorized = useCallback(
    (id_sub_menu: string) => {
      if (!isAdmin) {
        const permitted = data.find(p => p.id_sub_menu === id_sub_menu);
        if (!permitted) {
          return false;
        }
        return true;
      }
      return true;
    },
    [data, isAdmin],
  );

  const updateDiscipulo = useCallback((discipuloUpdate: any) => {
    setDiscipulo({
      ...discipuloUpdate,
    });
  }, []);

  const updateParametrosGerais = useCallback((parametrosGeraisUpdate: any) => {
    setParametrosGerais({
      ...parametrosGeraisUpdate,
    });
  }, []);

  const setFotoDiscipulo = useCallback((url: string) => {
    if (url) {
      setDiscipulo((prevState: any) => {
        return {
          ...prevState,
          foto_url: url,
        };
      });
      getBase64FromUrl(url).then(image => {
        setFoto(image);
      });
    }
  }, []);

  const resetPermissions = useCallback(() => {
    setData([]);
    setIsAdmin(false);
    setIdManager('');
    setModulo('');
    setDiscipulo(undefined);
    setParametrosGerais({});
    setLiderCelula(false);
    setCelulasLider([]);
    setLiderCasaPaz(false);
    setCasasPazLider([]);
    setLiderancaCelula(false);
    setLiderancasLider([]);
    setFacilitadorDepartamento(false);
    setFacilitacoesLider([]);
    setCoordenadorCelula(false);
    setCoordenacoesLider([]);
    setSupervisorCelula(false);
    setSupervisoesLider([]);
  }, []);

  return (
    <PermissionContext.Provider
      value={{
        permissions: data,
        parametrosGerais,
        getPermission,
        isAuthorized,
        resetPermissions,
        isAdmin,
        idManager,
        discipulo,
        moduloName: modulo,
        foto,
        setFotoDiscipulo,
        updateDiscipulo,
        updateParametrosGerais,
        liderCelula,
        celulasLider,
        liderCasaPaz,
        casasPazLider,
        liderancaCelula,
        liderancasLider,
        facilitadorDepartamento,
        facilitacoesLider,
        coordenadorCelula,
        coordenacoesLider,
        supervisorCelula,
        supervisoesLider,
      }}
    >
      {children}
    </PermissionContext.Provider>
  );
};

function usePermission(): IPermissionContextData {
  const context = useContext(PermissionContext);

  return context;
}

export { PermissionProvider, usePermission };
