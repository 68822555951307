import React, { useRef, useCallback } from 'react';
import { FiMail, FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import logoImg from '../../assets/lagoinha_preto.png';

import Input from '../../components/Input';
import Button from '../../components/Button';

import { Container, Content, AnimationContainer, Background } from './styles';
// import { useGeolocation } from '../../hooks/geolocation';
// import { formatValue } from '../../utils/formatValue';

interface ISignInFormData {
  login: string;
  senha: string;
}

const SignIn: React.FC = () => {
  // const { position } = useGeolocation();

  const formRef = useRef<FormHandles>(null);

  const { signIn } = useAuth();
  const { addToast } = useToast();

  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: ISignInFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          login: Yup.string().required('E-mail obrigatório'),
          senha: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({
          login: data.login,
          senha: data.senha,
        });

        history.push('/home');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu um erro ao fazer login, cheque as credenciais.',
        });
      }
    },
    [signIn, addToast, history],
  );

  return (
    <Container>
      <Background />

      <Content>
        <AnimationContainer>
          <img src={logoImg} height="200px" alt="IEQ Lagoinha" />

          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>Faça seu logon</h1>

            <Input name="login" icon={FiMail} placeholder="Login" />
            <Input
              name="senha"
              icon={FiLock}
              type="password"
              placeholder="Senha"
            />

            <Button type="submit">Entrar</Button>

            <Link to="/esqueci-senha">Esqueci minha senha</Link>
          </Form>
          {/* {position && (
            <>
              <div>
                <p>Lat:</p>
                <span>{position.latitude}</span>
                <p>Long:</p>
                <span>{position.longitude}</span>
              </div>
              <div>
                <p>Precisão:</p>
                <span>{position.accuracy}</span>
                <p>Distância:</p>
                <span>
                  {`${formatValue(Number(position.distanceIEQ?.toFixed(0)))} m`}
                </span>
              </div>
            </>
          )} */}
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default SignIn;
