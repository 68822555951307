/* eslint-disable @typescript-eslint/class-name-casing */
import {
  Paper,
  Table,
  TableSortLabel,
  TablePagination,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useStyles } from './styles';

interface headCells {
  id: string;
  label: string;
  disableSorting?: boolean;
}

interface groupHead {
  id: string;
  label: string;
  colSpan?: number;
}

export function UseTable(
  records: any,
  headCells: headCells[],
  filterFn: any,
  groupHead?: groupHead[],
): any {
  const classes = useStyles();

  const pages = [5, 10, 25, 50, 100];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pages[page]);
  const [order, setOrder] = useState<any>();
  const [orderBy, setOrderBy] = useState<any>();

  interface TableContentProps {
    children?: React.ReactNode;
  }

  const TblContainer: React.FC<TableContentProps> = ({ children }) => (
    <>
      <Paper className={classes.paper}>
        <Table className={classes.table}>{children}</Table>
      </Paper>
    </>
  );

  const TblHead = (props: any): any => {
    const handleSortRequest = (cellId: any): void => {
      const isAsc = orderBy === cellId && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(cellId);
    };

    return (
      <TableHead>
        {groupHead && (
          <TableRow>
            {groupHead.map(group => (
              <TableCell
                key={group.id}
                colSpan={group.colSpan}
                style={{ textAlign: 'center' }}
              >
                {group.label}
              </TableCell>
            ))}
          </TableRow>
        )}
        <TableRow>
          {headCells.map(headCell => (
            <TableCell
              key={headCell.id}
              style={{ textAlign: 'center' }}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.disableSorting ? (
                headCell.label
              ) : (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={() => {
                    handleSortRequest(headCell.id);
                  }}
                >
                  {headCell.label}
                </TableSortLabel>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const handleChangePage = (event: any, newPage: any): void => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any): void => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const TblPagination: React.FC = () => (
    <TablePagination
      component="div"
      page={page}
      rowsPerPageOptions={pages}
      rowsPerPage={rowsPerPage}
      count={records.length}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );

  function stableSort(array: any, comparator: any): any {
    const stabilizedThis = array.map((el: any, index: number) => [el, index]);
    stabilizedThis.sort((a: any, b: any) => {
      const order1 = comparator(a[0], b[0]);
      if (order1 !== 0) return order1;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el: any) => el[0]);
  }

  function getComparator(order1: any, orderBy1: any): any {
    return order1 === 'desc'
      ? (a: any, b: any) => descendingComparator(a, b, orderBy1)
      : (a: any, b: any) => -descendingComparator(a, b, orderBy1);
  }

  function descendingComparator(a: any, b: any, orderBy1: any): any {
    if (b[orderBy1] < a[orderBy1]) {
      return -1;
    }
    if (b[orderBy1] > a[orderBy1]) {
      return 1;
    }
    return 0;
  }

  const recordsAfterPagingAndSorting = (): any => {
    return stableSort(
      filterFn.fn(records),
      getComparator(order, orderBy),
    ).slice(page * rowsPerPage, (page + 1) * rowsPerPage);
  };

  const recordsAfterSorting = (): any => {
    return stableSort(filterFn.fn(records), getComparator(order, orderBy));
  };

  return {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
    recordsAfterSorting,
  };
}
