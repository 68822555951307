import React from 'react';
import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Button,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useStyles } from './styles';

interface ActionDialog {
  isOpen: boolean;
  title: string;
  subTitle: string;
  onConfirm?: () => void;
}

type ActionProps = DialogProps & {
  actionDialog: ActionDialog;
  setActionDialog: React.Dispatch<React.SetStateAction<ActionDialog>>;
};

export const ActionDialog: React.FC<ActionProps> = ({
  actionDialog,
  setActionDialog,
}) => {
  const classes = useStyles();

  return (
    <Dialog open={actionDialog.isOpen} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>
        <IconButton disableRipple className={classes.titleIcon}>
          <CheckCircleIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="h6">{actionDialog.title}</Typography>
        <Typography variant="subtitle2">{actionDialog.subTitle}</Typography>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button
          color="default"
          onClick={() => setActionDialog({ ...actionDialog, isOpen: false })}
        >
          Não
        </Button>
        <Button color="default" onClick={actionDialog.onConfirm}>
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  );
};
