import React from 'react'
import { FormControl, FormLabel, RadioGroup as MuiRadioGroup, FormControlLabel, Radio, FormHelperText } from '@material-ui/core';

export default function RadioGroup(props) {

    const { name, label, value, onChange, error=null, items, ...other } = props;

    return (
        <FormControl {...(error && {error:true})}>
            <FormLabel>{label}</FormLabel>
            <MuiRadioGroup row
                {...other}
                name={name}
                value={value}
                onChange={onChange}>
                {
                    items.map(
                        item => (
                            <FormControlLabel key={item.id} value={item.id} control={<Radio />} label={item.title} />
                        )
                    )
                }
            </MuiRadioGroup>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    )
}
