import React, { useEffect } from 'react';
import { Grid, Button } from '@material-ui/core';
import { useForm, Form } from '../../../components/UseForm/useForm.js';
import { useStyles } from './styles';
import Controls from '../../../components/controls/Controls.js';

interface IValue {
  id?: string;
  nome?: string;
  observacao?: string;
}

interface IError {
  observacao?: string;
}

const initialFValues = {
  id: '',
  nome: '',
  observacao: '',
} as IValue;

const initialFErrors = {
  observacao: '',
} as IError;

interface IBatizanteObsFormProps {
  addOrEdit: any;
  recordForEdit: IValue | null;
}

const BatizanteObsForm: React.FC<IBatizanteObsFormProps> = ({
  addOrEdit,
  recordForEdit,
}) => {
  const classes = useStyles();

  const validate = (fieldValues: IValue = values): boolean | undefined => {
    const temp: IError = { ...errors };
    setErrors({
      ...temp,
    });

    if (fieldValues === values) return Object.values(temp).every(x => x === '');
    return undefined;
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, initialFErrors, true, validate);

  useEffect(() => {
    if (recordForEdit !== null) {
      setValues({
        ...recordForEdit,
        observacao: recordForEdit.observacao || '',
      });
    }
  }, [recordForEdit, setValues]);

  const handleSubmit = (e: any): void => {
    e.preventDefault();
    if (validate()) {
      addOrEdit(values, resetForm);
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <Controls.Input
              name="observacao"
              label="Observação"
              multiline
              placeholder="Observação"
              value={values.observacao}
              onChange={handleInputChange}
              error={errors.observacao}
            />
          </Grid>
        </Grid>
        <div>
          <Button type="submit" className={classes.buttonForm}>
            Salvar
          </Button>
        </div>
      </Form>
    </>
  );
};

export default BatizanteObsForm;
