const b64toBlob = (b64Data: any, contentType?: any, sliceSize?: any): Blob => {
  const contentTypeImg = contentType || '';
  const sliceSizeImg = sliceSize || 512;

  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSizeImg) {
    const slice = byteCharacters.slice(offset, offset + sliceSizeImg);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentTypeImg });
  return blob;
};

export default b64toBlob;
