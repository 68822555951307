import React, { useState, useCallback, useEffect } from 'react'
import Cropper from 'react-easy-crop'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { getOrientation } from 'get-orientation/browser'
import { getCroppedImg, getRotatedImage } from './canvasUtils'
import { styles } from './styles'

const ORIENTATION_TO_ANGLE = {
  '3': 180,
  '6': 90,
  '8': -90,
}
const ImageCrop = ({ classes, fileToCrop, onCropped, aspect  }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  useEffect(()=>{
    if (fileToCrop) {
      const file = fileToCrop
      readFile(file).then((data) =>{
        // apply rotation if needed
        getOrientation(file).then((orientation) =>{
          const rotation = ORIENTATION_TO_ANGLE[orientation]
          if (rotation) {
            getRotatedImage(data, rotation).then((imageData) =>{
              setImageSrc(imageData)
            })
          }else{
            setImageSrc(data)
          }
        })
      })
    }
  },[fileToCrop])

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const cropImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      )
      // console.log('donee', { croppedImage })
      onCropped(croppedImage);
    } catch (e) {
      console.error(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageSrc, croppedAreaPixels, rotation])

  return (
    <div>
      {imageSrc && (
        <>
          <div className={classes.cropContainer}>
            <Cropper
              image={imageSrc}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={aspect}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className={classes.controls}>
            <Button
              onClick={cropImage}
              variant="contained"
              color="primary"
              classes={{ root: classes.cropButton }}
            >
              Selecionar
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}

export default withStyles(styles)(ImageCrop)

