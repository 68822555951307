import React, { useEffect, useState } from 'react';
import {
  Grid,
  TableBody,
  TableRow,
  TableCell,
  Fade,
  CircularProgress,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import axios from 'axios';
import FlatList from 'flatlist-react';
import Controls from '../../../components/controls/Controls.js';
import { UseTable } from '../../../components/index';
import { ItemBotoes, ItemLista, useStyles } from './styles';
import ActionButton from '../../../components/ActionButton/index';

const headCells = [
  { id: 'cep', label: 'CEP', disableSorting: true },
  { id: 'logradouro', label: 'Logradouro', disableSorting: true },
  { id: 'bairro', label: 'Bairro', disableSorting: true },
  { id: 'actions', label: 'Ações', disableSorting: true },
];

interface ICidade {
  id: string;
  nome: string;
  id_estado: string;
}

interface ICidadeSelect {
  id?: string;
  title?: string;
}

interface IEstadoSelect {
  id?: string;
  title?: string;
  sigla?: string;
}

interface IViaCEP {
  cep?: string;
  logradouro?: string;
  complemento?: string;
  bairro?: string;
  localidade?: string;
  uf?: string;
  ibge?: string;
  gia?: string;
  ddd?: string;
  siafi?: string;
  logradouroObs?: string;
}

interface ICEPFormProps {
  addOrEdit: any;
  estados: IEstadoSelect[];
  cidades: ICidade[];
}

const CEPForm: React.FC<ICEPFormProps> = ({ addOrEdit, estados, cidades }) => {
  const classes = useStyles();
  const [viacepResults, setViacepResults] = useState<IViaCEP[]>([]);
  const [filterFn] = useState({
    fn: (items: any) => {
      return items;
    },
  });
  const [cidadesEstado, setCidadesEstado] = useState<ICidadeSelect[]>([]);
  const [loadingEstados, setLoadingEstados] = useState(true);
  const [loadingCidades, setLoadingCidades] = useState(true);
  const [estado, setEstado] = useState<IEstadoSelect>({
    id: '',
    title: '',
    sigla: '',
  });
  const [cidade, setCidade] = useState<ICidadeSelect>({ id: '', title: '' });
  const [estadoAnterior, setEstadoAnterior] = useState('');
  const [cidadeAnterior, setCidadeAnterior] = useState('');
  const [idEstado, setIdEstado] = useState('');
  const [idCidade, setIdCidade] = useState('');

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = UseTable(
    viacepResults,
    headCells,
    filterFn,
  );

  const handleEstadoChange = (name: any, value: any): void => {
    const id_estado = value;
    setIdEstado(id_estado);
  };

  const handleCidadeChange = (name: any, value: any): void => {
    const id_cidade = value;
    setIdCidade(id_cidade);
  };

  useEffect(() => {
    setLoadingEstados(true);
    if (idEstado !== '' && idEstado !== estadoAnterior) {
      const estadoSelecionado = estados.find(e => e.id === idEstado);
      if (estadoSelecionado) {
        setEstado(estadoSelecionado);
        setLoadingCidades(true);
        setIdCidade('');
        setCidade({ id: '', title: '' });
        setCidadesEstado([]);
        const listaCidades = cidades.filter(c => c.id_estado === idEstado);
        const listaCidadesEstado = listaCidades.map(
          c =>
            ({
              id: c.id,
              title: c.nome,
            } as ICidadeSelect),
        );
        setCidadesEstado(listaCidadesEstado);
        setEstadoAnterior(idEstado);
        setLoadingCidades(false);
      }
    } else if (idEstado === '' && estado.id === '') {
      setEstado({ id: '', title: '', sigla: '' });
    }
    setLoadingEstados(false);
  }, [idEstado, estados, cidades, estado.id, estadoAnterior, idCidade]);

  useEffect(() => {
    setLoadingCidades(true);
    if (idCidade !== '' && idCidade !== cidadeAnterior) {
      const cidadeSelecionada = cidadesEstado.find(c => c.id === idCidade);
      if (cidadeSelecionada) {
        setCidade(cidadeSelecionada);
      }
      setCidadeAnterior(idCidade);
    } else if (idCidade === '' && cidade.id === '') {
      setCidade({ id: '', title: '' });
    }
    setLoadingCidades(false);
  }, [cidadesEstado, idCidade, cidade.id, cidadeAnterior]);

  const handleLogradouroChange = (e: any): void => {
    const logradouroInput = e.target.value.replace(' ', '+');
    if (
      logradouroInput.length > 3 &&
      estado.sigla !== '' &&
      cidade.title !== ''
    ) {
      axios
        .get<IViaCEP[]>(
          `${process.env.REACT_APP_VIACEP_API_URL}/ws/${estado.sigla}/${cidade.title}/${logradouroInput}/json/`,
        )
        .then(
          response => {
            const dados = response.data;
            const listaDados = dados.map(dt => {
              let logradouroObs;
              if (dt.complemento && dt.complemento !== '') {
                logradouroObs = `${dt.logradouro} (${dt.complemento})`;
              } else {
                logradouroObs = dt.logradouro;
              }
              return {
                ...dt,
                logradouroObs,
              };
            });
            setViacepResults(listaDados);
          },
          error => {
            console.log(error);
          },
        );
    }
  };

  const renderData = (data: IViaCEP, idx: any): any => {
    return (
      <li key={idx}>
        <ItemLista>
          <div>
            <div>
              <p>CEP:</p>
              <span>{data.cep}</span>
            </div>
            <div>
              <p>Logradouro:</p>
              <span>{data.logradouroObs}</span>
            </div>
            <div>
              <p>Bairro:</p>
              <span>{data.bairro}</span>
            </div>
            <ItemBotoes>
              <ActionButton
                type="button"
                width="25px"
                height="25px"
                backgroundColor="darkgreen"
                fontColor="#fff"
                onClick={() => {
                  addOrEdit(data.cep);
                }}
              >
                <CheckIcon fontSize="small" />
              </ActionButton>
            </ItemBotoes>
          </div>
        </ItemLista>
      </li>
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Controls.AutoComplete
              name="id_estado"
              label="Estado"
              value={estado}
              disabled={cidades.length === 0}
              onChange={handleEstadoChange}
              options={estados}
              loading={loadingEstados}
              loadingText="Carregando..."
              style={{ width: '250px' }}
            />
            <Fade
              in={cidades.length === 0 && !loadingEstados}
              style={{
                transitionDelay:
                  cidades.length === 0 && !loadingEstados ? '800ms' : '0ms',
              }}
              unmountOnExit
            >
              <CircularProgress color="secondary" size={30} />
            </Fade>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Controls.AutoComplete
              name="id_cidade"
              label="Cidade"
              disabled={loadingCidades}
              value={cidade}
              onChange={handleCidadeChange}
              options={cidadesEstado}
              loading={loadingCidades}
              loadingText="Carregando..."
              style={{ width: '250px' }}
            />
            <Fade
              in={loadingCidades}
              style={{
                transitionDelay: loadingCidades ? '800ms' : '0ms',
              }}
              unmountOnExit
            >
              <CircularProgress color="secondary" size={30} />
            </Fade>
          </div>
          <Controls.Input
            name="logradouro"
            label="Endereço"
            placeholder="Endereço"
            onChange={handleLogradouroChange}
            disabled={cidades.length === 0}
            style={{ width: '250px' }}
          />
        </Grid>
        <Grid item xs={12}>
          {viacepResults.length > 0 && (
            <>
              <div className={classes.desktop}>
                <TblContainer>
                  <TblHead />
                  <TableBody>
                    {recordsAfterPagingAndSorting().map((item: any) => (
                      <TableRow key={item.cep}>
                        <TableCell>{item.cep}</TableCell>
                        <TableCell>{item.logradouroObs}</TableCell>
                        <TableCell>{item.bairro}</TableCell>
                        <TableCell>
                          <Controls.ActionButton
                            color="tertiary"
                            onClick={() => {
                              addOrEdit(item.cep);
                            }}
                          >
                            <CheckIcon fontSize="small" />
                          </Controls.ActionButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </TblContainer>
              </div>
              <div className={classes.mobile}>
                <ul>
                  <FlatList
                    list={viacepResults}
                    renderItem={renderData}
                    renderWhenEmpty={() => (
                      <div style={{ textAlign: 'center', marginTop: '30px' }}>
                        Nenhum Resultado!
                      </div>
                    )}
                    sortBy={[{ key: 'logradouro', ascending: true }]}
                  />
                </ul>
              </div>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default CEPForm;
