import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  right: 0;
  top: 60px;
  padding: 20px;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    top: 60px;
    padding: 5px;
    right: 5px;
  }
`;
