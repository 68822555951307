import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

import mainPageBackgroundImg from '../../../assets/imagem_fundo.jpg';

interface INetworkStatusProps {
  isOnline: boolean;
}

export const Container = styled.div`
  height: 100vh;

  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 700px;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  animation: ${appearFromLeft} 1s;

  form {
    margin: 30px 0;
    width: 340px;
    text-align: center;

    h2 {
      margin-bottom: 20px;
      color: #000000;
    }

    a {
      color: #000000;
      display: block;
      margin-top: 24px;
      text-decoration: none;
      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#000000')};
      }
    }
  }

  > a {
    color: #000000;
    display: block;
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;

    display: flex;
    align-items: center;

    svg {
      margin-right: 16px;
    }

    &:hover {
      color: ${shade(0.2, '#000000')};
    }
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: center;

    > p {
      margin-left: 10px;
      font-style: italic;
    }

    > span {
      margin-left: 10px;
      font-weight: bold;
    }
  }
`;

export const Background = styled.div`
  flex: 1;
  background: url(${mainPageBackgroundImg}) no-repeat center;
  background-size: cover;
  opacity: 0.5;
`;

export const AreaRestrita = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 10;

  svg {
    height: 30px;
    width: 30px;
    z-index: 20;
    color: #ffffff;
    //color: #000000;

    //transition: filter 0.2s;

    /* &:hover {
      //filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
      filter: drop-shadow(0 0 15px red);
    } */
  }
`;

export const NetworkStatus = styled.div<INetworkStatusProps>`
  position: absolute;
  right: 80px;
  top: 15px;
  z-index: 10;

  svg {
    height: 30px;
    width: 30px;
    z-index: 20;
    color: ${props => (props.isOnline ? '#000000' : 'red')};
  }
`;

export const QrCodeDownload = styled.div`
  position: relative;
  align-self: center;
  width: 200px;

  img {
    width: 170px;
    height: 170px;
    border-radius: 4px;
  }

  div {
    position: absolute;
    width: 48px;
    height: 48px;
    background: #e41e26;
    border-radius: 50%;
    right: 0;
    bottom: 0;
    border: 0;
    cursor: pointer;
    transition: background-color 0.2s;
    z-index: 20;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 20px;
      height: 20px;
      color: #ffffff;
      z-index: 20;
    }

    &:hover {
      background: ${shade(0.2, '#e41e26')};
    }
  }
`;
