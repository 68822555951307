const removeAccents = (strAccents: string): string => {
  const strAccentsOriginal: string[] = strAccents.split('');
  const strAccentsOriginalOut: string[] = [];
  const strAccentsOriginalLen = strAccentsOriginal.length;
  const accents =
    'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
  const accentsOut = [
    'A',
    'A',
    'A',
    'A',
    'A',
    'A',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'O',
    'O',
    'O',
    'O',
    'O',
    'O',
    'O',
    'o',
    'o',
    'o',
    'o',
    'o',
    'o',
    'E',
    'E',
    'E',
    'E',
    'e',
    'e',
    'e',
    'e',
    'e',
    'C',
    'c',
    'D',
    'I',
    'I',
    'I',
    'I',
    'i',
    'i',
    'i',
    'i',
    'U',
    'U',
    'U',
    'U',
    'u',
    'u',
    'u',
    'u',
    'N',
    'n',
    'S',
    's',
    'Y',
    'y',
    'y',
    'Z',
    'z',
  ];
  for (let y = 0; y < strAccentsOriginalLen; y += 1) {
    if (accents.indexOf(strAccentsOriginal[y]) !== -1) {
      strAccentsOriginalOut[y] =
        accentsOut[accents.indexOf(strAccentsOriginal[y])];
    } else strAccentsOriginalOut[y] = strAccentsOriginal[y];
  }
  const strWithoutAccents = strAccentsOriginalOut.join('');
  return strWithoutAccents;
};

export default removeAccents;
