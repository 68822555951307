import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    zIndex: theme.zIndex.drawer + 1,
    // zIndex: 1500,
    position: 'fixed',
  },
  navIconHide: {
    margin: -15,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      opacity: 0,
    },
  },
  themeChanger: {
    color: theme.palette.tertiary.main,
  },
  imageHeader: {
    marginRight: '40px',
    [theme.breakpoints.down('xs')]: {
      marginRight: '10px',
      marginLeft: '15px',
    },
  },
  userHeader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginRight: '40px',
    [theme.breakpoints.down('xs')]: {
      marginRight: '5px',
    },
  },
  linkUser: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      color: '#999',
    },
  },
}));
