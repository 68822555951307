import React, { createContext, useContext, useEffect, useState } from 'react';
import api from '../services/api';

interface INetworkContextData {
  isOnline: boolean;
  serverConection: boolean;
  setIsOnline: React.Dispatch<React.SetStateAction<boolean>>;
}

const NetworkContext = createContext<INetworkContextData>(
  {} as INetworkContextData,
);

const NetworkProvider: React.FC = ({ children }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [serverConection, setServerConection] = useState(false);

  useEffect(() => {
    if (isOnline) {
      api
        .get(`/server-on`)
        .then(() => {
          setServerConection(true);
        })
        .catch(() => {
          setServerConection(false);
        });
    } else {
      setServerConection(false);
    }
  }, [isOnline]);

  window.addEventListener('load', () => {
    window.addEventListener('online', () => {
      setIsOnline(true);
    });

    window.addEventListener('offline', () => {
      setIsOnline(false);
    });
  });

  return (
    <NetworkContext.Provider
      value={{
        isOnline,
        setIsOnline,
        serverConection,
      }}
    >
      {children}
    </NetworkContext.Provider>
  );
};

function useNetwork(): INetworkContextData {
  const context = useContext(NetworkContext);

  return context;
}

export { NetworkProvider, useNetwork };
