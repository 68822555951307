import React, { useRef, useCallback } from 'react';
import { FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';

import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';

import logoImg from '../../../assets/lagoinha_preto.png';

import Input from '../../../components/Input';
import Button from '../../../components/Button';

import { Container, Content, AnimationContainer, Background } from './styles';
import api from '../../../services/api';
import { encrypt } from '../../../services/crypt';

interface IResetarSenhaFormData {
  senha: string;
  senha_confirmacao: string;
}

const ResetarSenha: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();

  const history = useHistory();
  const location = useLocation();

  const handleSubmit = useCallback(
    async (data: IResetarSenhaFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          senha: Yup.string().required('Senha obrigatória'),
          senha_confirmacao: Yup.string().oneOf(
            [Yup.ref('senha'), undefined],
            'Confirmação incorreta',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { senha, senha_confirmacao } = data;
        const token = location.search.replace('?token=', '');

        if (!token) {
          throw new Error();
        }

        await api.post(
          `/senha/resetar`,
          {
            payload: encrypt(
              JSON.stringify({
                senha,
                senha_confirmacao,
                token,
              }),
            ),
          },
          {
            headers: {
              activity: encrypt('Esqueci minha senha'),
            },
          },
        );

        history.push('/');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao resetar senha',
          description: 'Ocorreu um erro ao resetar sua senha, tente novamente.',
        });
      }
    },
    [addToast, history, location.search],
  );

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <img src={logoImg} height="150px" alt="IEQ Lagoinha" />

          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>Resetar senha</h1>

            <Input
              name="senha"
              icon={FiLock}
              type="password"
              placeholder="Nova senha"
            />

            <Input
              name="senha_confirmacao"
              icon={FiLock}
              type="password"
              placeholder="Confirmação da senha"
            />

            <Button type="submit">Alterar senha</Button>
          </Form>
        </AnimationContainer>
      </Content>

      <Background />
    </Container>
  );
};

export default ResetarSenha;
